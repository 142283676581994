"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.educationReducer = exports.useEducationReducer = exports.initialEducationState = exports.initialEducationSecondary = exports.initialEducationProfessionalValue = exports.initialEducationHighValue = void 0;
const types_1 = require("api/references/employees/types");
const react_1 = require("react");
const parsers_1 = require("api/files/parsers");
exports.initialEducationHighValue = {
    id: null,
    educationalInstitution: null,
    studyStartDate: null,
    educationType: types_1.EducationType.COMPLETE,
    faculty: '',
    specialty: '',
    diplomaNumber: '',
    releaseDate: null,
    dateOfAwardQualifications: null,
    assign: types_1.Assign.ACADEMIC_DEGREE,
    qualification: '',
    academicDegrees: undefined,
    titleOfMastersTopic: '',
    titleOfDoctoralTopic: '',
    files: {
        diploma: [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.DIPLOMA }],
        other: [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.OTHER }]
    }
};
exports.initialEducationProfessionalValue = {
    id: null,
    educationalInstitution: null,
    studyStartDate: null,
    educationType: types_1.EducationType.COMPLETE,
    diplomaNumber: '',
    releaseDate: null,
    assign: types_1.Assign.QUALIFICATION,
    dateOfAwardQualifications: null,
    qualification: '',
    programName: '',
    files: {
        diploma: [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.DIPLOMA }],
        other: [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.OTHER }]
    }
};
exports.initialEducationSecondary = {
    id: null,
    educationType: types_1.EducationType.COMPLETE,
    educationalInstitution: null,
    studyStartDate: null,
    graduationDate: null,
    diplomaNumber: '',
    releaseDate: null,
    files: {
        diploma: [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.DIPLOMA }],
        other: [{ ...parsers_1.initialFile, type: types_1.EmployeeEducationFileTypes.OTHER }]
    }
};
const initialEducationValue = {
    highs: [],
    professionals: [],
    secondaries: [],
    high: exports.initialEducationHighValue,
    professional: exports.initialEducationProfessionalValue,
    secondary: exports.initialEducationSecondary
};
exports.initialEducationState = {
    data: initialEducationValue,
    errors: null,
    status: null,
    isFetching: false,
    isFetched: false,
    isLoading: false,
    isCreatingOrUpdatingEducationHigh: false,
    isCreatingOrUpdatingEducationProfessional: false,
    isCreatingOrUpdatingEducationSecondary: false,
    isDeletingHigh: false,
    isDeletingProfessional: false,
    isDeletingSecondary: false
};
const useEducationReducer = () => {
    return (0, react_1.useReducer)(exports.educationReducer, exports.initialEducationState);
};
exports.useEducationReducer = useEducationReducer;
const educationReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_EDUCATION_HIGHS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_HIGHS_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    highs: action.payload.data || state.data.highs
                },
                errors: action.payload.errors
                    ? state.errors
                        ? state.errors.concat(action.payload.errors) : action.payload.errors
                    : state.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationProfessional
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'SEND_EDUCATION_PROFESSIONALS_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_PROFESSIONALS_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    professionals: action.payload.data || state.data.professionals
                },
                errors: action.payload.errors
                    ? state.errors
                        ? state.errors.concat(action.payload.errors) : action.payload.errors
                    : state.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationProfessional
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'SEND_EDUCATION_SECONDARIES_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_SECONDARIES_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    secondaries: action.payload.data || state.data.secondaries
                },
                errors: action.payload.errors
                    ? state.errors
                        ? state.errors.concat(action.payload.errors) : action.payload.errors
                    : state.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationProfessional
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'SEND_EDUCATION_HIGH_CREATE_OR_UPDATE':
            return {
                ...state,
                errors: null,
                isCreatingOrUpdatingEducationHigh: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_HIGH_CREATE_OR_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    highs: action.payload.data || state.data.highs
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isCreatingOrUpdatingEducationHigh: false,
                isLoading: state.isFetching
                    || state.isCreatingOrUpdatingEducationProfessional
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'SEND_EDUCATION_PROFESSIONAL_CREATE_OR_UPDATE':
            return {
                ...state,
                errors: null,
                isCreatingOrUpdatingEducationProfessional: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_PROFESSIONAL_CREATE_OR_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    professionals: action.payload.data || state.data.professionals
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isCreatingOrUpdatingEducationProfessional: false,
                isLoading: state.isFetching
                    || state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'SEND_EDUCATION_SECONDARY_CREATE_OR_UPDATE':
            return {
                ...state,
                errors: null,
                isCreatingOrUpdatingEducationSecondary: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_SECONDARY_CREATE_OR_UPDATE':
            return {
                ...state,
                data: {
                    ...state.data,
                    secondaries: action.payload.data || state.data.secondaries
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isCreatingOrUpdatingEducationSecondary: false,
                isLoading: state.isFetching
                    || state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationProfessional
            };
        case 'SEND_EDUCATION_HIGH_DELETE_REQUEST':
            return {
                ...state,
                isDeletingHigh: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_HIGH_DELETE_REQUEST':
            return {
                ...state,
                isDeletingHigh: false,
                data: {
                    ...state.data,
                    highs: state.data.highs && state.data.highs.filter(el => !action.payload.includes(el.id))
                },
                isLoading: state.isFetching
                    || state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationProfessional
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'SEND_EDUCATION_PROFESSIONAL_DELETE_REQUEST':
            return {
                ...state,
                isDeletingProfessional: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_PROFESSIONAL_DELETE_REQUEST':
            return {
                ...state,
                isDeletingProfessional: false,
                data: {
                    ...state.data,
                    professionals: state.data.professionals && state.data.professionals.filter(el => !action.payload.includes(el.id))
                },
                isLoading: state.isFetching
                    || state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationProfessional
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'SEND_EDUCATION_SECONDARY_DELETE_REQUEST':
            return {
                ...state,
                isDeletingSecondary: true,
                isLoading: true
            };
        case 'DONE_EDUCATION_SECONDARY_DELETE_REQUEST':
            return {
                ...state,
                isDeletingSecondary: false,
                data: {
                    ...state.data,
                    secondaries: state.data.secondaries && state.data.secondaries.filter(el => !action.payload.includes(el.id))
                },
                isLoading: state.isFetching
                    || state.isCreatingOrUpdatingEducationHigh
                    || state.isCreatingOrUpdatingEducationProfessional
                    || state.isCreatingOrUpdatingEducationSecondary
            };
        case 'EDUCATION_FETCHED':
            return {
                ...state,
                isFetched: action.payload
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.educationReducer = educationReducer;
