"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const submissionAutoComplete_1 = require("../store/submissionAutoComplete");
const types_1 = require("api/documents/purchases/submissions/types");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const submissions_1 = require("api/documents/purchases/submissions");
const useSubmissionAutoCompleteForCorrection = () => {
    const [state, dispatch] = (0, submissionAutoComplete_1.useSubmissionAutoCompleteReducer)();
    const [submissionList, setSubmissionList] = (0, react_1.useState)(false);
    const isMounted = (0, react_1.useRef)(false);
    const getSubmissionAutoComplete = async (params) => {
        dispatch({ type: 'SEND_SUBMISSION_AUTOCOMPLETE_REQUEST' });
        const submissionAutoComplete = await (0, submissions_1.getSubmissionsForCorrection)(params);
        if (isMounted.current)
            dispatch({ type: 'DONE_SUBMISSION_AUTOCOMPLETE_REQUEST', payload: submissionAutoComplete });
    };
    const debouncedSearch = (value) => {
        const params = ({ filters: { status: types_1.SubmissionsStatuses.FOR_CORRECTION, keyword: value ?? undefined, hasProcurement: false }
        });
        (0, debouncedMethods_1.debouncedOnFunc)(params, getSubmissionAutoComplete);
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        isMounted.current = true;
        debouncedSearch('');
        return () => { isMounted.current = false; };
    }, []);
    return { state, debouncedSearch, setSubmissionList, submissionList, closeErrorMessage };
};
exports.default = useSubmissionAutoCompleteForCorrection;
