"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateUserPermissions = exports.getStructuralPermissions = exports.getUserPermissions = exports.getAclPermissions = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parser_1 = require("api/administer/permission/parser");
const getAclPermissions = async (params) => {
    const response = await Api.get('acl/permissions', params);
    return (0, parser_1.parsePermissions)(response);
};
exports.getAclPermissions = getAclPermissions;
const getUserPermissions = async (userId) => {
    const response = await Api.get('acl/permissions', { filters: { userId: userId } });
    return (0, parser_1.parseAttachedPermissions)(response);
};
exports.getUserPermissions = getUserPermissions;
const getStructuralPermissions = async (structuralUnitId) => {
    const response = await Api.get('acl/permissions', { filters: { structuralUnitId } });
    return (0, parser_1.parseAttachedPermissions)(response);
};
exports.getStructuralPermissions = getStructuralPermissions;
const updateUserPermissions = async (id, params) => {
    const response = await Api.patch(`acl/permissions/attach/user/${id}`, params);
    return (0, parser_1.parseAttachedPermissions)(response);
};
exports.updateUserPermissions = updateUserPermissions;
