"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseDecimals = exports.transformNegativeNumberInBrackets = exports.calculateCurrentPage = exports.isAnyFilterValue = exports.objectIsEmpty = exports.transformMeForSelect = exports.getRandomIntBetween = exports.getHash = void 0;
const getHash = (radix = 16, from = 2, length = 8) => {
    return Math.random().toString(radix).slice(from, length);
};
exports.getHash = getHash;
const getRandomIntBetween = (max, min) => {
    return Math.floor(Math.random() * (max - min) + min);
};
exports.getRandomIntBetween = getRandomIntBetween;
const transformMeForSelect = (authUser) => {
    if (authUser === null)
        return null;
    const email = authUser.email ? ' (' + authUser.email + ')' : '';
    return {
        id: authUser.id,
        label: authUser.fullName + email
    };
};
exports.transformMeForSelect = transformMeForSelect;
const objectIsEmpty = (obj) => {
    return Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};
exports.objectIsEmpty = objectIsEmpty;
const isAnyFilterValue = (obj) => {
    return Object.entries(obj).some(([key, value]) => {
        if (key === 'perPage' || key === 'page')
            return false;
        return !!value;
    });
};
exports.isAnyFilterValue = isAnyFilterValue;
// export const isAnyFilterValue = (url: string): boolean => {
// 	const urlParams = new URLSearchParams(url)
// 	urlParams.delete('perPage')
// 	urlParams.delete('page')
// 	const valuesArray = [...urlParams.values()]
// 	return !valuesArray.some(item => item !== '')
// }
const calculateCurrentPage = (pagination, deletingItemCount) => {
    if (pagination.count === deletingItemCount) {
        if (pagination.currentPage === 1 && pagination.totalPages > 1)
            return 1;
        return pagination.currentPage - 1; //NOTE this returns 0 if there is only 1 page and current is 1st page. so it won't re-fetch. otherwise it returns previous page
    }
    return 0;
};
exports.calculateCurrentPage = calculateCurrentPage;
const transformNegativeNumberInBrackets = (num, toFixed = 2) => {
    return num < 0 ? '(' + parseFloat((Math.abs(num)).toFixed(toFixed)) + ')' : parseFloat(num.toFixed(toFixed));
};
exports.transformNegativeNumberInBrackets = transformNegativeNumberInBrackets;
const parseDecimals = (num, toFixed = 4) => {
    if (!num && num !== 0)
        return null;
    // toFixed Formats the number with up to 4 decimal places
    // parseFloat Converts it back to a number to remove trailing zeros
    return parseFloat(num.toFixed(toFixed));
};
exports.parseDecimals = parseDecimals;
