"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCalculateItemsV2 = void 0;
const procurementCalculateItemsV2_1 = require("src/modules/documents/purchases/procurements/store/procurementCalculateItemsV2");
const api_1 = require("src/api");
const react_1 = require("react");
const useCalculateItemsV2 = (id) => {
    const [state, dispatch] = (0, procurementCalculateItemsV2_1.useProcurementCalculateItemsV2Reducer)();
    const getProcurementCalculateItemsV2 = async () => {
        dispatch({ type: 'SEND_PROCUREMENT_CALCULATE_ITEMS_V2_REQUEST' });
        const items = await api_1.ProcurementsApi.getProcurementCalculateItemsV2(id);
        dispatch({ type: 'DONE_PROCUREMENT_CALCULATE_ITEMS_V2_REQUEST', payload: items });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { getProcurementCalculateItemsV2().then(); }, []);
    return { state, closeErrorMessage };
};
exports.useCalculateItemsV2 = useCalculateItemsV2;
