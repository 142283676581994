"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const calculateFundingSourcesReducerV2_1 = require("src/modules/documents/purchases/submissionVerifies/store/calculateFundingSourcesReducerV2");
const api_1 = require("src/api");
const react_1 = require("react");
const useCalculateFundingSourcesV2 = (props) => {
    const [state, dispatch] = (0, calculateFundingSourcesReducerV2_1.useCalculateFundingSourcesReducerV2)();
    const [error, setError] = (0, react_1.useState)('');
    const getFundingSources = async (id) => {
        dispatch({ type: 'SEND_CALCULATE_FUNDING_SOURCES_V2_REQUEST' });
        const response = await api_1.SubmissionVerifiesApi.getFundingResourcesV2(id);
        if (props.items.some(el => !el.quantity || !el.price)) {
            setError('გთხოვთ შეავსოთ გამოტოვებული ველები. აუცილებელია ველებია: ოდენობა, ერთეულის ფასი, ვალუტა და გაცვლითი კურსი');
        }
        dispatch({ type: 'DONE_CALCULATE_FUNDING_SOURCES_V2_REQUEST', payload: response });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getFundingSources(props.id).then(); }, [props.id]);
    return { state, closeErrorMessage, error };
};
exports.default = useCalculateFundingSourcesV2;
