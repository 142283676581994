"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employeeTabNames = exports.sendingContractCorrectionText = exports.sendingContractText = exports.transformSendContractParams = exports.transformContractAndAnnexCorrectionParams = exports.transformContractAndAnnexParams = exports.EmployeeSecondaryEducationFiles = exports.EmployeeEducationFiles = exports.parseProjectsInfo = exports.transformEmployeeAssignmentParamsV2 = exports.transformEmployeeAssignmentParams = exports.transformEmployeeTaxReliefsParams = exports.transformEmployeeDismissalsParams = exports.showDismissalReasonAttributes = exports.transformEmployeeTrainingsParams = exports.transformEmployeeLanguageParams = exports.transformEmployeeFamilyMemberParams = exports.transformEmployeeBankAccountParams = exports.transformEducationSecondary = exports.transformEducationProfessional = exports.transformEducationHighParams = exports.transformEmployeeSignatoryFiles = exports.transformEmployeeDocumentFiles = exports.transformEmployeeParams = exports.transformNotifyPendingContractFilesParams = exports.transformEmployeeFilterParamsToUrl = exports.transformEmployeeParamsToFilterParams = exports.transformUrlToEmployeeFilterParams = void 0;
const types_1 = require("api/references/employees/types");
const types_2 = require("api/documents/human-resources/assign-submissions/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_3 = require("api/references/structuralUnitsUpgraded/types");
const helper_1 = require("src/modules/common/helper");
const parsers_1 = require("api/parsers/parsers");
const queryString_1 = require("core/helpers/queryString");
const helpers_1 = require("../../../documents/hr/assign-submissions/helpers");
const transformUrlToEmployeeFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        structuralUnit: values.structuralUnitId && values.structuralUnitLabel
            ? { id: values.structuralUnitId, label: values.structuralUnitLabel }
            : undefined,
        status: values.statusId && values.statusLabel ? { id: values.statusId, label: values.statusLabel } : undefined,
        userContractFileStatus: values.userContractFileStatusId && values.userContractFileStatusLabel
            ? { id: values.userContractFileStatusId, label: values.userContractFileStatusLabel }
            : undefined,
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage,
        onlyMine: !!values.onlyMine,
        noApproveFacsimile: values.noApproveFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        noFacsimile: values.noFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        facsimileStatus: values.facsimileStatusId && values.facsimileStatusLabel
            ? { id: values.facsimileStatusId, label: values.facsimileStatusLabel }
            : undefined,
        changedFacsimile: values.changedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        usedFacsimile: values.usedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        hasUsedFacsimile: values.hasUsedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined
    };
};
exports.transformUrlToEmployeeFilterParams = transformUrlToEmployeeFilterParams;
const transformEmployeeParamsToFilterParams = (params, accountId) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        noApproveFacsimile: params.noApproveFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        noFacsimile: params.noFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        facsimileStatus: params.facsimileStatus?.id,
        changedFacsimile: params.changedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        usedFacsimile: params.usedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        hasUsedFacsimile: params.hasUsedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        keyword: params.keyword,
        structuralUnitId: params.structuralUnit?.id,
        status: params.status?.id,
        userContractFilesCreatedBy: params.onlyMine ? accountId : undefined,
        userContractFileStatus: params.userContractFileStatus?.id,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformEmployeeParamsToFilterParams = transformEmployeeParamsToFilterParams;
const transformEmployeeFilterParamsToUrl = (values, accountId) => {
    const { page, perPage, keyword, status, structuralUnit, noApproveFacsimile, noFacsimile, facsimileStatus, changedFacsimile, hasUsedFacsimile, usedFacsimile, userContractFileStatus, onlyMine } = values;
    return {
        page,
        perPage,
        keyword,
        statusId: status?.id,
        onlyMine: onlyMine ? accountId : undefined,
        statusLabel: status?.label,
        userContractFileStatusId: userContractFileStatus?.id,
        userContractFileStatusLabel: userContractFileStatus?.label,
        structuralUnitId: structuralUnit?.id,
        structuralUnitLabel: structuralUnit?.label,
        noApproveFacsimile: noApproveFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        noFacsimile: noFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        facsimileStatusId: facsimileStatus?.id,
        facsimileStatusLabel: facsimileStatus?.label,
        changedFacsimile: changedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        usedFacsimile: usedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined,
        hasUsedFacsimile: hasUsedFacsimile ? apiGlobalTypes_1.YesNoStatus.YES : undefined
    };
};
exports.transformEmployeeFilterParamsToUrl = transformEmployeeFilterParamsToUrl;
const transformNotifyPendingContractFilesParams = (showNotifyModal, ids, notificationText) => {
    return {
        text: notificationText,
        allContractFiles: showNotifyModal === types_1.NotifyModalType.ALL_CONTRACTS,
        contractFileIds: showNotifyModal === types_1.NotifyModalType.ALL_CONTRACTS ? [] : ids.map(id => id)
    };
};
exports.transformNotifyPendingContractFilesParams = transformNotifyPendingContractFilesParams;
const transformEmployeeParams = (params) => {
    return {
        firstname: params.firstname,
        lastname: params.lastname,
        firstnameEn: params.firstnameLatin,
        firstStartDate: (0, helper_1.newNullableTransformParamsDate)(params.firstStartDate),
        lastnameEn: params.lastnameLatin,
        identityNumber: params.identityNumber,
        idCardValidTo: (0, helper_1.nullableTransformParamsDate)(params.idCardValidTo),
        passportNumber: params.passportNumber,
        passportValidTo: params.passportValidTo,
        gender: params.gender,
        birthDate: (0, helper_1.newNullableTransformParamsDate)(params.birthDate),
        actualAddress: params.actualAddress,
        legalAddress: params.legalAddress,
        mobileNumber: params.mobileNumber,
        workPhoneNumber: params.workPhoneNumber,
        personalEmail: params.personalEmail,
        email: params.email,
        postIndex: params.postIndex,
        personalCaseNumber: params.personalCaseNumber,
        countryId: params.country && params.country.id,
        foreignCitizen: params.foreignCitizen === types_1.Citizen.FOREIGN,
        residenceCertificateNumber: params.residenceCertificateNumber,
        residenceCertificateEndDate: (0, helper_1.nullableTransformParamsDate)(params.residenceCertificateEndDate),
        resident: params.foreignCitizen === types_1.Citizen.FOREIGN ? params.resident : null,
        administrativeBuildingId: !params.noWorkPlace ? params.administrativeBuilding?.id : null,
        administrativeBuildingRoomId: !params.noWorkPlace ? params.administrativeBuildingRoom?.id : null,
        noWorkPlace: params.noWorkPlace
    };
};
exports.transformEmployeeParams = transformEmployeeParams;
const transformEmployeeDocumentFiles = (file) => {
    return {
        fileUids: [{
                uid: file.id,
                type: file.type
            }]
    };
};
exports.transformEmployeeDocumentFiles = transformEmployeeDocumentFiles;
const transformEmployeeSignatoryFiles = (files) => {
    return {
        fileUids: [
            {
                document_uuid: files.pending?.signatureApprove && files.pending.signatureApprove.id ? files.pending.signatureApprove.id : undefined,
                signature_uuid: files.pending?.signature && files.pending.signature.id ? files.pending.signature.id : undefined
            }
        ]
    };
};
exports.transformEmployeeSignatoryFiles = transformEmployeeSignatoryFiles;
const transformEducationHighParams = (params) => {
    const diploma = params.files.diploma.filter(file => file.id);
    const other = params.files.other.filter(file => file.id);
    const files = params.educationType === types_1.EducationType.COMPLETE ? [...diploma, ...other] : other;
    const fullParams = params.educationType === types_1.EducationType.COMPLETE ?
        {
            diplomaNumber: params.diplomaNumber,
            releaseDate: (0, helper_1.nullableTransformParamsDate)(params.releaseDate),
            dateOfAwardQualifications: (0, helper_1.nullableTransformParamsDate)(params.dateOfAwardQualifications),
            assign: params.assign,
            qualification: params.assign === types_1.Assign.QUALIFICATION ? params.qualification : undefined,
            academicDegree: params.assign === types_1.Assign.ACADEMIC_DEGREE ? Number(params.academicDegrees?.id) : undefined,
            assignedDegree: params.academicDegrees?.id ? params.assignedDegree : undefined,
            titleOfMastersTopic: params.assign === types_1.Assign.ACADEMIC_DEGREE ? (params.academicDegrees)?.id === String(types_1.AcademicDegree.EQ_MASTER)
                || (params.academicDegrees)?.id === String(types_1.AcademicDegree.MASTER)
                ? params.titleOfMastersTopic : undefined : undefined,
            titleOfDoctoralTopic: (params.academicDegrees)?.id === String(types_1.AcademicDegree.EQ_DOCTOR)
                || (params.academicDegrees)?.id === String(types_1.AcademicDegree.DOCTOR)
                ? params.titleOfMastersTopic : undefined
        } : {};
    return ({
        id: params.id,
        educationType: params.educationType,
        educationalInstitutionId: params.educationalInstitution ? params.educationalInstitution.id : null,
        studyStartDate: (0, helper_1.nullableTransformParamsDate)(params.studyStartDate),
        faculty: params.faculty,
        specialty: params.specialty,
        fileUids: files.map(file => ({ uid: file.id, type: file.type })),
        ...fullParams
    });
};
exports.transformEducationHighParams = transformEducationHighParams;
const transformEducationProfessional = (params) => {
    const diploma = params.files.diploma.filter(file => file.id);
    const other = params.files.other.filter(file => file.id);
    const files = params.educationType === types_1.EducationType.COMPLETE ? [...diploma, ...other] : other;
    const fullParams = params.educationType === types_1.EducationType.COMPLETE ?
        {
            diplomaNumber: params.diplomaNumber,
            releaseDate: (0, helper_1.nullableTransformParamsDate)(params.releaseDate),
            assign: params.qualification ? types_1.Assign.QUALIFICATION : undefined,
            dateOfAwardQualifications: (0, helper_1.nullableTransformParamsDate)(params.dateOfAwardQualifications),
            qualification: params.qualification && params.qualification.length > 0 ? params.qualification : undefined,
            fileUids: files.map(file => ({ uid: file.id, type: file.type }))
        } : {};
    return ({
        id: params.id,
        educationType: params.educationType,
        educationalInstitutionId: params.educationalInstitution && params.educationalInstitution.id || null,
        programName: params.programName,
        studyStartDate: (0, helper_1.nullableTransformParamsDate)(params.studyStartDate),
        fileUids: files.map(file => ({ uid: file.id, type: file.type })),
        ...fullParams
    });
};
exports.transformEducationProfessional = transformEducationProfessional;
const transformEducationSecondary = (params) => {
    const diploma = params.files.diploma.filter(file => file.id);
    const other = params.files.other.filter(file => file.id);
    const files = [...diploma, ...other];
    return ({
        id: params.id,
        educationType: params.educationType,
        educationalInstitutionId: String(params.educationalInstitution?.id),
        diplomaNumber: params.diplomaNumber,
        releaseDate: (0, helper_1.nullableTransformParamsDate)(params.releaseDate),
        studyStartDate: (0, helper_1.nullableTransformParamsDate)(params.studyStartDate),
        graduationDate: (0, helper_1.nullableTransformParamsDate)(params.graduationDate),
        fileUids: files.map(file => ({ uid: file.id, type: file.type }))
    });
};
exports.transformEducationSecondary = transformEducationSecondary;
const transformEmployeeBankAccountParams = (data) => {
    return {
        items: [
            {
                id: data.id,
                bankId: data.bank.id,
                accountNumber: data.accountNumber,
                requisitesUid: data.requisitesMediaFile?.id || null,
                status: data.bankStatus
            }
        ]
    };
};
exports.transformEmployeeBankAccountParams = transformEmployeeBankAccountParams;
const transformEmployeeFamilyMemberParams = (data) => {
    return {
        items: [
            {
                firstname: data.firstname,
                lastname: data.lastname,
                phone: data.phone
            }
        ]
    };
};
exports.transformEmployeeFamilyMemberParams = transformEmployeeFamilyMemberParams;
const transformEmployeeLanguageParams = (data) => {
    return {
        items: [{
                languageId: data.language.id,
                level: Number(data.level.id)
            }]
    };
};
exports.transformEmployeeLanguageParams = transformEmployeeLanguageParams;
const transformEmployeeTrainingsParams = (values) => {
    const files = values.files.filter(el => el.id).map(file => ({ uid: file.id }));
    return {
        items: [
            {
                withFunding: values.withFunding !== types_1.Funding.NO,
                name: values.name,
                dateTo: (0, helper_1.nullableTransformParamsDate)(values.dateTo),
                dateFrom: (0, helper_1.transformParamsDate)(values.dateFrom),
                hour: values.hour ?? null,
                credit: values.credit ?? null,
                fileUids: files.length > 0 ? files : null
            }
        ]
    };
};
exports.transformEmployeeTrainingsParams = transformEmployeeTrainingsParams;
const showDismissalReasonAttributes = (dismissalReason) => {
    return dismissalReason && (dismissalReason.id !== String(types_1.DismissalReasonType.END_DATE)
        && dismissalReason.id !== String(types_1.DismissalReasonType.JOB_PERFORMANCE)
        && dismissalReason.id !== String(types_1.DismissalReasonType.VIOLATION_ETHIC_CODE)
        && dismissalReason.id !== String(types_1.DismissalReasonType.OPTION_EXPIRATION)
        && dismissalReason.id !== String(types_1.DismissalReasonType.AWARDING_EMERITUS));
};
exports.showDismissalReasonAttributes = showDismissalReasonAttributes;
const transformEmployeeDismissalsParams = (values) => {
    return {
        reason: Number(values.dismissalReason.id),
        assignId: values.assign.id,
        dismissalDate: (0, helper_1.nullableTransformParamsDate)(values.dismissalDate),
        documentNumber: values.dismissalReason?.id === String(types_1.DismissalReasonType.END_DATE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.JOB_PERFORMANCE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.VIOLATION_ETHIC_CODE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.OPTION_EXPIRATION)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.AWARDING_EMERITUS)
            ? undefined
            : values.documentNumber,
        title: values.dismissalReason?.id === String(types_1.DismissalReasonType.END_DATE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.JOB_PERFORMANCE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.VIOLATION_ETHIC_CODE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.OPTION_EXPIRATION)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.AWARDING_EMERITUS)
            ? undefined
            : values.title,
        date: values.dismissalReason?.id === String(types_1.DismissalReasonType.END_DATE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.JOB_PERFORMANCE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.VIOLATION_ETHIC_CODE)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.OPTION_EXPIRATION)
            || values.dismissalReason?.id === String(types_1.DismissalReasonType.AWARDING_EMERITUS)
            ? undefined
            : (0, helper_1.nullableTransformParamsDate)(values.date)
    };
};
exports.transformEmployeeDismissalsParams = transformEmployeeDismissalsParams;
const transformEmployeeTaxReliefsParams = (data) => {
    return {
        taxReliefBenefit: data.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES,
        taxReliefAmount: data.taxReliefBenefit && data.useOfTaxRelief ? data.taxReliefAmount : null,
        taxReliefId: data.taxReliefBenefit && data.taxReliefId ? data.taxReliefId : null,
        useOfTaxRelief: data.taxReliefBenefit === apiGlobalTypes_1.YesNoStatus.YES && data.useOfTaxRelief
    };
};
exports.transformEmployeeTaxReliefsParams = transformEmployeeTaxReliefsParams;
const transformEmployeeAssignmentParams = (data, employeeId, correcting) => {
    const electedCandidate = parseElectedCandidate(data);
    const contractNumber = parseContractNumber(data);
    const contractDate = parseContractDate(data);
    const contractType = parseContractType(data);
    const orderNumber = parseOrderNumber(data);
    const orderDate = parseOrderDate(data);
    const oderTitle = parseOrderTitle(data);
    const continuationType = parseContinuationType(data);
    const employmentContractType = parseEmploymentContractType(data);
    return {
        items: [
            {
                id: !correcting ? data.assignId : undefined,
                userId: !correcting ? employeeId : undefined,
                assignCategory: data.assignCategory,
                ...electedCandidate,
                assignType: Number(data.assignType?.id),
                contractContinuationType: continuationType,
                contractNumber: contractNumber,
                employmentContractType,
                contractDate: contractDate ? (0, parsers_1.transformDateParams)(contractDate) : undefined,
                contractTypeId: contractType?.id,
                orderNumber: orderNumber,
                orderDate: orderDate ? (0, parsers_1.transformDateParams)(orderDate) : undefined,
                orderTitle: oderTitle,
                fileUids: [
                    ...parseFileByContractType(data),
                    ...data.files.basicFunctionDutiesFiles.filter(file => file.id !== '').map(el => ({ uid: el.id, type: el.type }))
                ],
                accounts: data.positionInfos.map(position => {
                    return {
                        ...(0, helpers_1.transformAssignSubmissionPosition)(position, correcting, true),
                        workDirection: undefined,
                        userId: employeeId,
                        positionId: position.structuralUnit?.type === types_3.StructuralUnitType.STRUCTURAL_UNIT ? position.positionName.id : null
                    };
                })
            }
        ]
    };
};
exports.transformEmployeeAssignmentParams = transformEmployeeAssignmentParams;
const transformEmployeeAssignmentParamsV2 = (data, employeeId) => {
    return {
        endDate: (0, parsers_1.transformDateParams)(data.positionInfos[0].endDate),
        startDate: (0, parsers_1.transformDateParams)(data.positionInfos[0].startDate),
        structuralUnitId: data.positionInfos[0].structuralUnit.id,
        userId: employeeId
    };
};
exports.transformEmployeeAssignmentParamsV2 = transformEmployeeAssignmentParamsV2;
const parseProjectsInfo = (projectInfo, correcting) => {
    return {
        id: !correcting ? projectInfo.id : null,
        projectId: projectInfo.project.id,
        workInfos: projectInfo.workInfos.map(workInfo => {
            return {
                id: !correcting ? workInfo.id : undefined,
                workload: Number(workInfo.workload.id),
                salaryType: Number(workInfo.salaryType.id),
                salaryAmount: Number(workInfo.salaryType.id) === types_2.SalaryType.FIXED ? workInfo.salaryAmount : null,
                currencyId: Number(workInfo.salaryType.id) === types_2.SalaryType.FIXED ? workInfo.currency.id : null,
                equivalentCurrencyId: Number(workInfo.salaryType.id) === types_2.SalaryType.FIXED ? workInfo.equivalentCurrency.id : null,
                salaryIssuanceType: Number(workInfo.salaryType.id) === types_2.SalaryType.FIXED ? Number(workInfo.salaryGivenType.id) : null,
                workTypeId: Number(workInfo.salaryType.id) === types_2.SalaryType.BY_JOB ? workInfo.workType.id : null,
                remunerationWork: Number(workInfo.salaryType.id) === types_2.SalaryType.BY_JOB ? Number(workInfo.remunerationWork.id) : null,
                items: Number(workInfo.salaryType.id) === types_2.SalaryType.BY_JOB
                    && Number(workInfo.remunerationWork.id) === types_3.RemunerationWorkPerformed.UNIT_FEE
                    ? (0, helpers_1.transformWorkInfoItemUnitFee)(workInfo)
                    : (0, helpers_1.transformWorkInfoItem)(Number(workInfo.salaryType.id), workInfo)
            };
        })
    };
};
exports.parseProjectsInfo = parseProjectsInfo;
const parseOrderNumber = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.ORDER) {
        return data.orderOrderNumber;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER_CONTRACT) {
        return data.ocOrderNumber;
    }
    return;
};
const parseContinuationType = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT_EXT) {
        return data.contractExtContinuationType;
    }
    return;
};
const parseEmploymentContractType = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT) {
        return data.employmentContractType;
    }
    return;
};
const parseOrderDate = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.ORDER) {
        return data.orderOrderDate;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER_CONTRACT) {
        return data.ocOrderDate;
    }
    return;
};
const parseOrderTitle = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.ORDER) {
        return data.orderOrderType;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER_CONTRACT) {
        return data.ocOrderType;
    }
    return;
};
const parseContractNumber = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT) {
        return data.contractContractNumber;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT_EXT) {
        return data.contractExtContractNumber;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER_CONTRACT) {
        return data.ocContractNumber;
    }
    return;
};
const parseContractDate = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT) {
        return data.contractContractDate;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT_EXT) {
        return data.contractExtContractDate;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER_CONTRACT) {
        return data.ocContractDate;
    }
    return;
};
const parseContractType = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT) {
        return data.contractContractType;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT_EXT) {
        return data.contractExtContractType;
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER_CONTRACT) {
        return data.ocContractType;
    }
    return;
};
const parseElectedCandidate = (data) => {
    if (data.assignCategory === types_2.AssignCategory.ELECTED) {
        return {
            recordNumber: data.recordNumber,
            recordDate: (0, parsers_1.nullableTransformDate)(data.recordDate),
            title: data.title,
            effectiveDate: (0, parsers_1.nullableTransformDate)(data.effectiveDate)
        };
    }
    return;
};
const parseFileByContractType = (data) => {
    if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT) {
        return data.files.contract.filter(file => file.id !== '').map(el => ({ uid: el.id, type: el.type }));
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.CONTRACT_EXT) {
        return data.files.contractExt.filter(file => file.id !== '').map(el => ({ uid: el.id, type: el.type }));
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER_CONTRACT) {
        return [...data.files.orderWithContract_Contract, ...data.files.orderWithContract_Order].filter(file => file.id !== '').map(el => ({ uid: el.id, type: el.type }));
    }
    else if (Number(data.assignType?.id) === types_2.AssignType.ORDER) {
        return data.files.order.filter(file => file.id !== '').map(el => ({ uid: el.id, type: el.type }));
    }
    return [];
};
exports.EmployeeEducationFiles = [
    {
        name: 'diploma',
        title: 'დიპლომი',
        type: types_1.EmployeeEducationFileTypes.DIPLOMA
    },
    {
        name: 'other',
        title: 'სხვა',
        type: types_1.EmployeeEducationFileTypes.OTHER
    }
];
exports.EmployeeSecondaryEducationFiles = [
    {
        name: 'diploma',
        title: 'ატესტატი',
        type: types_1.EmployeeEducationFileTypes.DIPLOMA
    },
    {
        name: 'other',
        title: 'სხვა',
        type: types_1.EmployeeEducationFileTypes.OTHER
    }
];
const transformContractAndAnnexParams = (values) => {
    return {
        fileUids: [{
                contract_uid: values.contract.id,
                attachment_uid: values.annex.id
            }],
        startDate: (0, parsers_1.transformDateParams)(values.startDate),
        endDate: (0, parsers_1.transformDateParams)(values.endDate),
        degreeId: values.academicDegree?.id || null,
        degreeName: values.academicDegree?.label || null,
        semesterId: values.semester?.id || null,
        semesterName: values.semester?.label || null
    };
};
exports.transformContractAndAnnexParams = transformContractAndAnnexParams;
const transformContractAndAnnexCorrectionParams = (values) => {
    return {
        fileUids: [{
                contract_uid: values.contractCorrection.id,
                attachment_uid: values.annexCorrection.id
            }],
        startDate: (0, parsers_1.transformDateParams)(values.startDate),
        endDate: (0, parsers_1.transformDateParams)(values.endDate),
        degreeId: values.academicDegree?.id || null,
        degreeName: values.academicDegree?.label || null,
        semesterId: values.semester?.id || null,
        semesterName: values.semester?.label || null
    };
};
exports.transformContractAndAnnexCorrectionParams = transformContractAndAnnexCorrectionParams;
const transformSendContractParams = (text, contractId) => {
    return {
        text: text,
        allContractFiles: false,
        contractFileIds: [contractId]
    };
};
exports.transformSendContractParams = transformSendContractParams;
exports.sendingContractText = 'ხელმოსაწერად გიგზავნით თქვენსა და სსიპ ილიას სახელმწიფო უნივერსიტეტს შორის გასაფორმებელ შრომით ხელშეკრულებას და ხელშეკრულების დანართის პროექტს. გთხოვთ, გაეცნოთ დოკუმენტს. ხელშეკრულების პროექტში რაიმე უზუსტობის შემთხვევაში, შეგიძლიათ, დააფიქსიროთ ხარვეზი და კომენტარის სახით მიუთითოთ ხარვეზის შესახებ ინფორმაცია, თანხმობის შემთხვევაში კი დაგვიდასტუროთ თქვენი ხელმოწერით (ხელმოწერა დამუშავებულია „ხელმოწერის გაციფრულებისა და გამოყენების შესახებ“ თქვენი თანხმობის დოკუმენტის საფუძველზე). თქვენი მხრიდან ხელმოწერის შემდეგ, დამსაქმებლის მხრიდან ხელმოწერისთანავე, სამსახურებრივ ფოსტაზე მოგივათ შრომითი ხელშეკრულების თქვენი ეგზემპლარი. გთხოვთ, გახსნათ დოკუმენტი';
exports.sendingContractCorrectionText = 'ხელმოსაწერად გიგზავნით თქვენსა და სსიპ ილიას სახელმწიფო უნივერსიტეტს შორის გასაფორმებელ შრომით ხელშეკრულებაში ცვლილების/დამატების პროექტს. გთხოვთ, გაეცნოთ დოკუმენტს. ცვლილების/დამატების პროექტში რაიმე უზუსტობის შემთხვევაში, შეგიძლიათ, დააფიქსიროთ ხარვეზი და კომენტარის სახით მიუთითოთ ხარვეზის შესახებ ინფორმაცია, თანხმობის შემთხვევაში კი დაგვიდასტუროთ თქვენი ხელმოწერით (ხელმოწერა დამუშავებულია „ხელმოწერის გაციფრულებისა და გამოყენების შესახებ“ თქვენი თანხმობის დოკუმენტის საფუძველზე). თქვენი მხრიდან ხელმოწერის შემდეგ, დამსაქმებლის მხრიდან ხელმოწერისთანავე, სამსახურებრივ ფოსტაზე მოგივათ შრომით ხელშეკრულებაში ცვლილების/დამატების შეთანხმების თქვენი ეგზემპლარი. გთხოვთ, გახსნათ დოკუმენტი';
exports.employeeTabNames = ['employeeDocuments', 'educations', 'trainings', 'languages', 'assignment', 'dismissal'];
