"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCpvCodes = exports.parseCpvCodesForSelect = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseCpvCodesForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const cpvCodes = response.content;
    try {
        return {
            data: cpvCodes.data.map(el => {
                return {
                    id: el.id,
                    label: el.attributes.code + ' - ' + el.attributes.name,
                    code: el.attributes.code,
                    name: el.attributes.name
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseCpvCodesForSelect = parseCpvCodesForSelect;
const parseCpvCodes = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const cpvCodes = response.content;
    try {
        return {
            data: cpvCodes.data.map(el => {
                return {
                    id: el.id,
                    name: el.attributes.code + ' - ' + el.attributes.name,
                    hasChildren: el.attributes.hasChildren,
                    closed: true,
                    level: 0,
                    children: []
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(cpvCodes.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCpvCodes = parseCpvCodes;
