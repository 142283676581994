"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementMultiYearHistory = void 0;
const procurementMultiYearHistory_1 = require("src/modules/documents/purchases/procurements/store/procurementMultiYearHistory");
const api_1 = require("src/api");
const react_1 = require("react");
const useProcurementMultiYearHistory = (props) => {
    const [state, dispatch] = (0, procurementMultiYearHistory_1.useProcurementMultiYearHistoryReducer)();
    const getProcurementMultiYearHistory = async () => {
        dispatch({ type: 'SEND_MULTI_YEAR_HISTORY_REQUEST' });
        const response = await api_1.ProcurementsApi.getProcurementMultiYearHistory(props.id);
        dispatch({ type: 'DONE_MULTI_YEAR_HISTORY_REQUEST', payload: response });
    };
    (0, react_1.useEffect)(() => { getProcurementMultiYearHistory().then(); }, []);
    return { state };
};
exports.useProcurementMultiYearHistory = useProcurementMultiYearHistory;
