"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.employeeDocumentFilesReducer = exports.useEmployeeDocumentFilesReducer = exports.initialEmployeeDocumentFilesState = void 0;
const react_1 = require("react");
const types_1 = require("api/references/employees/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const parsers_1 = require("api/files/parsers");
const initialEmployeeDocumentFiles = {
    mediaFiles: {
        cv: [],
        identityPassportResidence: [],
        schoolCertificate: [],
        conviction: [],
        taxBenefit: [],
        healthNotice: [],
        certificate: [],
        drivingLicense: [],
        pwdNoticeType: [],
        other: []
    },
    educationHighMediaFiles: {
        highDiploma: [],
        otherHighEducation: []
    },
    bankAccountMediaFiles: {
        bank: []
    },
    trainingMediaFiles: {
        trainings: []
    },
    educationSecondaryMediaFiles: {
        secondaryDiploma: [],
        otherSecondaryEducation: []
    },
    educationProfessionalMediaFiles: {
        professionalDiploma: [],
        otherProfessionalEducation: []
    }
};
exports.initialEmployeeDocumentFilesState = {
    data: initialEmployeeDocumentFiles,
    errors: null,
    status: null,
    isFetching: false,
    isUpdating: false,
    isLoading: false,
    isDeleting: false
};
const useEmployeeDocumentFilesReducer = () => {
    return (0, react_1.useReducer)(exports.employeeDocumentFilesReducer, exports.initialEmployeeDocumentFilesState);
};
exports.useEmployeeDocumentFilesReducer = useEmployeeDocumentFilesReducer;
const employeeDocumentFilesReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_EMPLOYEE_DOCUMENT_FILES_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_EMPLOYEE_DOCUMENT_FILES_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors
                    ? state.errors
                        ? state.errors.concat(action.payload.errors) : action.payload.errors
                    : state.errors,
                status: action.payload.status,
                isFetching: false,
                isLoading: state.isUpdating
            };
        case 'SEND_EMPLOYEE_DOCUMENT_FILES_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_EMPLOYEE_DOCUMENT_FILES_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isUpdating: false,
                status: action.payload.status,
                isLoading: state.isFetching
            };
        case 'SEND_DOCUMENT_FILE_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: true,
                isLoading: true
            };
        case 'DONE_DOCUMENT_FILE_DELETE_REQUEST':
            return {
                ...state,
                isDeleting: false,
                errors: action.payload.errors
                    ? state.errors
                        ? state.errors.concat(action.payload.errors) : action.payload.errors
                    : state.errors,
                data: action.payload.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS ? filterDocumentFiles(action.payload.uids, state.data) : state.data,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'UPDATE_STATE_ITEMS':
            return {
                ...state,
                data: action.payload
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.employeeDocumentFilesReducer = employeeDocumentFilesReducer;
const employeeDocumentFileTypesStatusName = {
    [types_1.EmployeeDocumentFileTypes.CV]: 'cv',
    [types_1.EmployeeDocumentFileTypes.IDENTITY_PASSPORT_RESIDENCE]: 'identityPassportResidence',
    [types_1.EmployeeDocumentFileTypes.CONVICTION]: 'conviction',
    [types_1.EmployeeDocumentFileTypes.TAX_BENEFIT]: 'taxBenefit',
    [types_1.EmployeeDocumentFileTypes.HEALTH_NOTICE]: 'healthNotice',
    [types_1.EmployeeDocumentFileTypes.DRIVING_LICENSE]: 'drivingLicense',
    [types_1.EmployeeDocumentFileTypes.PWD_NOTICE_TYPE]: 'pwdNoticeType',
    [types_1.EmployeeDocumentFileTypes.OTHER]: 'other'
};
const filterDocumentFiles = (uids, employeeDocumentFileTypes) => {
    uids.forEach(el => {
        const fileTypeName = el.fileType && employeeDocumentFileTypesStatusName[el.fileType];
        employeeDocumentFileTypes.mediaFiles[fileTypeName] = employeeDocumentFileTypes.mediaFiles[fileTypeName].filter(file => el.uid !== file.id);
        if (employeeDocumentFileTypes.mediaFiles[fileTypeName].length === 0)
            employeeDocumentFileTypes.mediaFiles[fileTypeName] = [{ ...parsers_1.initialFile, type: el.fileType }];
    });
    return employeeDocumentFileTypes;
};
