"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCategories = exports.getCategoriesForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../privateRequest"));
const parsers_1 = require("./parsers");
const getCategoriesForSelect = async (params) => {
    const response = await Api.get('references/inventory/categories', params);
    return (0, parsers_1.parseCategoriesForSelect)(response);
};
exports.getCategoriesForSelect = getCategoriesForSelect;
const getCategories = async (params) => {
    const response = await Api.get('references/inventory/categories', params);
    return (0, parsers_1.parseCategories)(response);
};
exports.getCategories = getCategories;
