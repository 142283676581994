"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const budgetSource_1 = require("../store/budgetSource");
const index_1 = require("api/index");
const helpers_1 = require("../helpers");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const useBudgetSource = (props) => {
    const [state, dispatch] = (0, budgetSource_1.useBudgetSourceReducer)();
    const [fundingSources, setFundingSources] = (0, react_1.useState)([]);
    const navigate = (0, react_router_dom_1.useNavigate)();
    const getBudgetSource = async (id) => {
        dispatch({ type: 'SEND_BUDGET_SOURCE_REQUEST' });
        const budgetSource = await index_1.BudgetSourcesApi.getBudgetSource(id);
        dispatch({ type: 'DONE_BUDGET_SOURCE_REQUEST', payload: budgetSource });
    };
    const createBudgetSource = async (values) => {
        const params = (0, helpers_1.transformBudgetSourceParams)(values);
        dispatch({ type: 'SEND_BUDGET_SOURCE_CREATE' });
        const budgetSource = await index_1.BudgetSourcesApi.createBudgetSource(params);
        dispatch({ type: 'DONE_BUDGET_SOURCE_CREATE', payload: budgetSource });
        if (budgetSource.data !== null) {
            navigate(`/references/funding/budget-sources/${budgetSource.data.id}/edit`);
        }
        return budgetSource;
    };
    const updateBudgetSource = async (id, values) => {
        const params = (0, helpers_1.transformBudgetSourceParams)((values));
        dispatch({ type: 'SEND_BUDGET_SOURCE_UPDATE' });
        const budgetSource = await index_1.BudgetSourcesApi.updateBudgetSource(id, params);
        dispatch(({ type: 'DONE_BUDGET_SOURCE_UPDATE', payload: budgetSource }));
        return budgetSource;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const getFundingSourceForSelect = async () => {
        const fundingSources = await index_1.FundingSourcesApi.getFundingSourcesForSelect({ perPage: 300 });
        setFundingSources(fundingSources.data);
    };
    (0, react_1.useEffect)(() => { getFundingSourceForSelect().then(); }, []);
    (0, react_1.useEffect)(() => { if (props.id)
        getBudgetSource(props.id).then(); }, [props.id]);
    return { state, createBudgetSource, updateBudgetSource, getBudgetSource, fundingSources, closeErrorMessage };
};
exports.default = useBudgetSource;
