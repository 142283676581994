"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWarehouse = exports.deleteWarehouse = exports.updateWarehouse = exports.createWarehouse = exports.getWarehousesForSelect = exports.getWarehouses = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/warehouses/parsers");
const getWarehouses = async (params) => {
    const response = await Api.get('references/warehouses', params);
    return (0, parsers_1.parseWarehouses)(response);
};
exports.getWarehouses = getWarehouses;
const getWarehousesForSelect = async (params) => {
    const response = await Api.get('references/warehouses', params);
    return (0, parsers_1.parseWarehousesForSelect)(response);
};
exports.getWarehousesForSelect = getWarehousesForSelect;
const createWarehouse = async (params) => {
    const response = await Api.post('references/warehouses', params);
    return (0, parsers_1.parseWarehouse)(response);
};
exports.createWarehouse = createWarehouse;
const updateWarehouse = async (id, params) => {
    const response = await Api.patch(`references/warehouses/${id}`, params);
    return (0, parsers_1.parseWarehouse)((response));
};
exports.updateWarehouse = updateWarehouse;
const deleteWarehouse = async (id) => {
    return await Api.deleteItem('references/warehouses/' + id);
};
exports.deleteWarehouse = deleteWarehouse;
const getWarehouse = async (id) => {
    const response = await Api.get('references/warehouses/' + id);
    return (0, parsers_1.parseWarehouse)(response);
};
exports.getWarehouse = getWarehouse;
