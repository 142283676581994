"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const employeeDocumentFiles_1 = require("../store/employeeDocumentFiles");
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const helper_1 = require("../helper");
const useEmployeeDocumentFiles = (props) => {
    const [state, dispatch] = (0, employeeDocumentFiles_1.useEmployeeDocumentFilesReducer)();
    const [deleteDocument, setDeleteDocument] = (0, react_1.useState)({ showAlert: false, ids: [] });
    const getEmployeeDocumentFiles = async (id) => {
        dispatch({ type: 'SEND_EMPLOYEE_DOCUMENT_FILES_REQUEST' });
        const employeeDocumentFiles = await index_1.EmployeesApi.getEmployeeDocumentFiles(id);
        dispatch({ type: 'DONE_EMPLOYEE_DOCUMENT_FILES_REQUEST', payload: employeeDocumentFiles });
    };
    const uploadEmployeeDocumentFile = async (id, file) => {
        const params = (0, helper_1.transformEmployeeDocumentFiles)(file);
        dispatch({ type: 'SEND_EMPLOYEE_DOCUMENT_FILES_UPDATE' });
        const uploadEmployeeDocumentFiles = await index_1.EmployeesApi.updateEmployeeDocumentFiles(id, params);
        if (uploadEmployeeDocumentFiles.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            const employeeDocumentFiles = await index_1.EmployeesApi.getEmployeeDocumentFiles(id);
            dispatch({ type: 'DONE_EMPLOYEE_DOCUMENT_FILES_UPDATE', payload: employeeDocumentFiles });
        }
        else {
            dispatch({ type: 'DONE_EMPLOYEE_DOCUMENT_FILES_UPDATE', payload: { ...uploadEmployeeDocumentFiles, data: state.data } });
        }
        return { errors: uploadEmployeeDocumentFiles.errors, status: uploadEmployeeDocumentFiles.status };
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const updateStateItems = (files) => {
        dispatch({ type: 'UPDATE_STATE_ITEMS', payload: files });
    };
    const deleteDocumentFile = async (confirm) => {
        if (!confirm || !deleteDocument.ids) {
            setDeleteDocument({ showAlert: false, ids: [] });
            setDeleteDocument(prevState => { return prevState; });
            return false;
        }
        setDeleteDocument(({ ...deleteDocument, showAlert: false }));
        dispatch({ type: 'SEND_DOCUMENT_FILE_DELETE_REQUEST' });
        const response = await index_1.EmployeesApi.deleteDocumentFile(props.userId, { fileUids: deleteDocument.ids.map(el => ({ uid: el.uid })) });
        dispatch({ type: 'DONE_DOCUMENT_FILE_DELETE_REQUEST', payload: {
                status: response.status,
                errors: response.errors,
                uids: deleteDocument.ids
            } });
        setDeleteDocument({ showAlert: false, ids: [] });
        return response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS;
    };
    (0, react_1.useEffect)(() => { if (props.userId)
        getEmployeeDocumentFiles(props.userId).then(); }, [props.userId]);
    return {
        state,
        uploadEmployeeDocumentFile,
        deleteDocumentFile,
        deleteDocument,
        setDeleteDocument,
        updateStateItems,
        getEmployeeDocumentFiles,
        closeErrorMessage
    };
};
exports.default = useEmployeeDocumentFiles;
