"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseCreateSubmission = exports.parseConditionalSubmissionItemStatus = exports.parseConditionalSubmissionFiles = exports.parseConditionalSubmissionMediaFiles = exports.parseDetermination = exports.parseDeterminations = exports.parseConditionalSubmission = exports.parseConditionalSubmissionsItem = exports.parseConditionalSubmissionItems = exports.parseConditionalSubmissions = void 0;
const types_1 = require("api/documents/purchases/conditional-submissions/types");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_2 = require("api/documents/purchases/submissionVerifies/types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parsers_2 = require("api/files/parsers");
const parsers_3 = require("api/references/funding/projects/parsers");
const parseConditionalSubmissions = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const conditionalSubmissions = response.content;
    try {
        return {
            data: conditionalSubmissions.data.map(el => {
                const structuralUnit = el.relationships.account.data.relationships.structuralUnit;
                const project = el.relationships.account.data.relationships.project;
                const creatorStructuralUnit = el.relationships.creator.data.relationships.structuralUnit;
                const creatorProject = el.relationships.creator.data.relationships.project;
                return {
                    id: el.id,
                    moduleType: el.type,
                    contactPersonFullname: el.relationships.contactPerson?.data.attributes.fullname || null,
                    edocNumber: el.attributes.edocNumber,
                    number: el.attributes.number,
                    requestRegistrationDate: el.attributes.requestRegistrationDate,
                    status: el.attributes.status,
                    subStatus: el.attributes.subStatus,
                    termDate: (0, parsers_1.transformDate)(el.attributes.termDate),
                    termDateTime: (0, parsers_1.transformDateTime)(el.attributes.termDate),
                    visualStatus: parseConditionalSubmissionVisualStatuses(el.attributes.status),
                    visualSentBy: el.attributes.sentBy && parseConditionalSubmissionSentBy(el.attributes.sentBy),
                    visualSubStatus: el.attributes.subStatus && parseConditionalSubmissionVisualSubStatuses(el.attributes.subStatus),
                    structuralUnit: structuralUnit
                        ? structuralUnit.data.attributes.name
                        : 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    // author: el.relationships.user.data.attributes.fullname,
                    author: el.relationships.account.data.relationships.user.data.attributes.fullname,
                    creator: el.relationships.creator.data.relationships.user.data.attributes.fullname,
                    creatorPosition: creatorStructuralUnit ? creatorStructuralUnit.data.attributes.name : 'პროექტის "' + creatorProject?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი',
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt),
                    hasDeterminations: el.attributes.hasDeterminations,
                    forceMajeureComment: el.attributes.forceMajeureComment
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(conditionalSubmissions.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseConditionalSubmissions = parseConditionalSubmissions;
const parseConditionalSubmissionItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const conditionalSubmissionItem = response.content;
    try {
        return {
            data: conditionalSubmissionItem.data.map(item => {
                const inventoryConfigurationType = item.relationships.inventoryConfiguration.data.attributes.type;
                const project = item.relationships.project.data;
                const submissionItems = item.relationships.submissionItems && item.relationships.submissionItems.data[0];
                const alertTime = project.attributes.endDate ? new Date() > new Date(project.attributes.endDate) : false;
                return {
                    id: item.id,
                    purchaseSubject: {
                        subjectName: item.relationships.inventoryConfiguration.data.attributes.itemTitle,
                        specificationName: item.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
                        verified: item.relationships.inventoryConfiguration.data.attributes.verified
                    },
                    dimensionUnit: {
                        id: item.relationships.dimensionUnit.data.id,
                        label: item.relationships.dimensionUnit.data.attributes.name
                    },
                    quantity: item.attributes.quantity,
                    project: {
                        id: project.id,
                        label: (0, parsers_3.parseProjectFullName)(project),
                        shortName: (0, parsers_3.parseProjectShortName)(project),
                        disabled: project.attributes.endDate ? Date.now() > Date.parse(project.attributes.endDate) : false,
                        alert: alertTime ? 'პროექტის დასრულების დრო არის: ' + (0, parsers_1.nullableTransformDate)(project.attributes.endDate) : undefined
                    },
                    shortCharacteristics: item.attributes.characteristics ? item.attributes.characteristics.length > 50 ? item.attributes.characteristics.substring(0, 50) : null : null,
                    characteristics: item.attributes.characteristics,
                    assignment: item.attributes.assignment,
                    shortAssignment: item.attributes.assignment ? item.attributes.assignment.length > 50 ? item.attributes.assignment.substring(0, 50) : null : null,
                    inventoryConfigurationId: item.relationships.inventoryConfiguration.data.id,
                    inventoryConfigurationType,
                    bookLink: item.attributes.bookLink,
                    bookTitle: item.attributes.bookTitle,
                    bookIsbnCode: item.attributes.bookIsbnCode,
                    bookAuthor: item.attributes.bookAuthor,
                    submission: submissionItems && {
                        id: submissionItems.relationships.document.data.id,
                        number: submissionItems.relationships.document.data.attributes.number,
                        status: submissionItems.relationships.document.data.attributes.status
                    } || null,
                    sort: item.attributes.sort,
                    checked: false
                };
            }),
            meta: conditionalSubmissionItem.meta ? (0, parsers_1.transformPagination)(conditionalSubmissionItem.meta) : undefined,
            errors: null,
            status: response.status
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseConditionalSubmissionItems = parseConditionalSubmissionItems;
const parseConditionalSubmissionsItem = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const conditionalSubmissionItem = response.content;
    try {
        return {
            data: conditionalSubmissionItem.data.map(el => {
                return {
                    id: el.id,
                    quantity: el.attributes.quantity,
                    status: el.relationships.submissionItems?.data[0]?.relationships.document.data.attributes.status,
                    purchaseSubjectName: el.relationships.inventoryConfiguration.data.attributes.itemTitle,
                    purchaseSubjectSpecification: el.relationships.inventoryConfiguration.data.attributes.specificationTitle || '',
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt)
                };
            }),
            meta: (0, parsers_1.transformPagination)(conditionalSubmissionItem.meta),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseConditionalSubmissionsItem = parseConditionalSubmissionsItem;
const parseConditionalSubmission = (response) => {
    const date = new Date();
    date.setDate(date.getDate() + 30);
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const conditionalSubmission = response.content.data;
    try {
        const status = conditionalSubmission.attributes.status;
        const project = conditionalSubmission.relationships.account.data.relationships.project;
        const structuralUnit = conditionalSubmission.relationships.account.data.relationships.structuralUnit;
        const creatorStructuralUnit = conditionalSubmission.relationships.creator.data.relationships.structuralUnit;
        const creatorProject = conditionalSubmission.relationships.creator.data.relationships.project;
        return {
            data: {
                id: conditionalSubmission.id,
                edocNumber: conditionalSubmission.attributes.edocNumber,
                requestRegistrationDate: (0, parsers_1.nullableTransformDate)(conditionalSubmission.attributes.requestRegistrationDate),
                requestRegistrationDateTime: (0, parsers_1.transformDateTime)(conditionalSubmission.attributes.requestRegistrationDate),
                number: conditionalSubmission.attributes.number,
                author: {
                    id: conditionalSubmission.relationships.account.data.relationships.user.data.id,
                    label: conditionalSubmission.relationships.account.data.relationships.user.data.attributes.fullname +
                        `${conditionalSubmission.relationships.account.data.relationships.user.data.attributes.email
                            ? ` (${conditionalSubmission.relationships.account.data.relationships.user.data.attributes.email})` : ''}`
                },
                structuralUnit: {
                    id: structuralUnit ? structuralUnit.data.id : '',
                    accountId: conditionalSubmission.relationships.account.data.id,
                    label: structuralUnit
                        ? structuralUnit.data.attributes.name
                        : 'პროექტის "' + project.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                usedItemsCount: conditionalSubmission.attributes.usedItemsCount,
                itemsCount: conditionalSubmission.attributes.itemsCount,
                termDate: (0, parsers_1.transformDate)(conditionalSubmission.attributes.termDate),
                forceMajeureComment: conditionalSubmission.attributes.forceMajeureComment,
                contactPerson: conditionalSubmission.relationships.contactPerson && {
                    id: conditionalSubmission.relationships.contactPerson.data.id,
                    label: conditionalSubmission.relationships.contactPerson.data.attributes.fullname +
                        `${conditionalSubmission.relationships.contactPerson.data.attributes.email
                            ? ` (${conditionalSubmission.relationships.contactPerson.data.attributes.email})` : ''}`,
                    email: conditionalSubmission.attributes.contactPersonEmail,
                    phone: conditionalSubmission.attributes.contactPersonPhoneNumber
                },
                sentDate: (0, parsers_1.nullableTransformDate)(conditionalSubmission.attributes.sentDate),
                visualStatus: parseConditionalSubmissionVisualStatuses(conditionalSubmission.attributes.status),
                visualSubStatus: conditionalSubmission.attributes.subStatus && parseConditionalSubmissionVisualSubStatuses(conditionalSubmission.attributes.subStatus),
                visualSentBy: conditionalSubmission.attributes.sentBy && parseConditionalSubmissionSentBy(conditionalSubmission.attributes.sentBy),
                email: conditionalSubmission.attributes.contactPersonEmail,
                phoneNumber: conditionalSubmission.attributes.contactPersonPhoneNumber,
                createdAt: (0, parsers_1.transformDate)(conditionalSubmission.attributes.createdAt),
                createdDateTimeAt: (0, parsers_1.transformDateTime)(conditionalSubmission.attributes.createdAt),
                creator: {
                    id: conditionalSubmission.relationships.creator.data.relationships.user.data.id,
                    label: conditionalSubmission.relationships.creator.data.relationships.user.data.attributes.fullname,
                    accountId: conditionalSubmission.relationships.creator.data.id,
                    structuralUnitName: creatorStructuralUnit ? creatorStructuralUnit.data.attributes.name : 'პროექტის "' + creatorProject?.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                declineComment: conditionalSubmission.attributes.declineComment,
                suspendComment: conditionalSubmission.attributes.suspendComment,
                hasDeterminations: conditionalSubmission.attributes.hasDeterminations,
                status: conditionalSubmission.attributes.status,
                subStatus: conditionalSubmission.attributes.subStatus,
                sentBy: conditionalSubmission.attributes.sentBy,
                disabledStatus: status !== types_1.ConditionalSubmissionsStatuses.DRAFT,
                purpose: conditionalSubmission.attributes.purpose,
                files: (0, exports.parseConditionalSubmissionMediaFiles)(conditionalSubmission.relationships.mediaFiles?.data).filter(el => el.type === types_1.ConditionalSubmissionItemFileTypes.EDOC_DOCUMENT),
                additionalFiles: (0, exports.parseConditionalSubmissionMediaFiles)(conditionalSubmission.relationships.mediaFiles?.data).filter(el => el.type === types_1.ConditionalSubmissionItemFileTypes.ADDITIONAL),
                isOriginal: true,
                updatedAt: conditionalSubmission.attributes.updatedAt //TODO @Merab todo should be removed after Tornike migrate database
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseConditionalSubmission = parseConditionalSubmission;
const parseDeterminations = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const determinations = response.content;
    try {
        return {
            data: determinations.data.map(el => {
                const creatorStructuralUnit = el.relationships.createdBy.data.relationships.structuralUnit;
                const creatorProject = el.relationships.createdBy.data.relationships.project;
                return {
                    id: el.id,
                    edocNumber: el.attributes.edocNumber,
                    requestRegistrationDate: (0, parsers_1.nullableTransformDate)(el.attributes.requestRegistrationDate),
                    requestRegistrationDateTime: (0, parsers_1.transformDateTime)(el.attributes.requestRegistrationDate),
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt),
                    status: el.attributes.status,
                    declineComment: el.attributes.declineComment,
                    suspendComment: el.attributes.suspendComment,
                    number: el.attributes.number,
                    sentBy: el.attributes.sentBy,
                    disabledStatus: el.attributes.status !== types_1.DeterminationsStatuses.DRAFT,
                    visualStatus: parseDeterminationVisualStatuses(el.attributes.status),
                    hasDeterminations: el.attributes.hasDeterminations,
                    visualSentBy: el.attributes.sentBy && parseConditionalSubmissionSentBy(el.attributes.sentBy),
                    files: el.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.DeterminationItemFileTypes.EDOC_DOCUMENT).map(file => {
                        return {
                            type: file.attributes.pivots.type,
                            id: file.id,
                            name: file.attributes.name,
                            status: file.attributes.status,
                            link: file.attributes.downloadUrl,
                            extension: file.attributes.extension,
                            originalName: file.attributes.originalName
                        };
                    }),
                    creator: {
                        id: el.relationships.createdBy.data.relationships.user.data.id,
                        label: el.relationships.createdBy.data.relationships.user.data.attributes.fullname,
                        accountId: el.relationships.createdBy.data.id,
                        structuralUnitName: creatorStructuralUnit ? creatorStructuralUnit.data.attributes.name : 'პროექტის "' + creatorProject.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                    },
                    additionalFiles: el.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.DeterminationItemFileTypes.ADDITIONAL).map(file => {
                        return {
                            type: file.attributes.pivots.type,
                            id: file.id,
                            status: file.attributes.status,
                            extension: file.attributes.extension,
                            name: file.attributes.name,
                            link: file.attributes.downloadUrl,
                            originalName: file.attributes.originalName
                        };
                    }),
                    content: el.attributes.content,
                    isOriginal: false
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseDeterminations = parseDeterminations;
const parseDetermination = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const determination = response.content.data;
    try {
        const status = determination.attributes.status;
        const creatorStructuralUnit = determination.relationships.createdBy.data.relationships.structuralUnit;
        const creatorProject = determination.relationships.createdBy.data.relationships.project;
        return {
            data: {
                id: determination.id,
                edocNumber: determination.attributes.edocNumber,
                requestRegistrationDate: (0, parsers_1.nullableTransformDate)(determination.attributes.requestRegistrationDate),
                content: determination.attributes.content,
                disabledStatus: status !== types_1.DeterminationsStatuses.DRAFT,
                status,
                number: determination.attributes.number,
                visualStatus: parseDeterminationVisualStatuses(determination.attributes.status),
                visualSentBy: determination.attributes.sentBy && parseConditionalSubmissionSentBy(determination.attributes.sentBy),
                sentBy: determination.attributes.sentBy,
                hasDeterminations: determination.attributes.hasDeterminations,
                isOriginal: false,
                creator: {
                    id: determination.relationships.createdBy.data.relationships.user.data.id,
                    label: determination.relationships.createdBy.data.relationships.user.data.attributes.fullname,
                    accountId: determination.relationships.createdBy.data.id,
                    structuralUnitName: creatorStructuralUnit ? creatorStructuralUnit.data.attributes.name : 'პროექტის "' + creatorProject.data.attributes.name + '" ადმინისტრირებაზე პასუხისმგებელი პირი'
                },
                files: determination.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.DeterminationItemFileTypes.EDOC_DOCUMENT).map(file => {
                    return {
                        type: file.attributes.pivots.type,
                        id: file.id,
                        name: file.attributes.name,
                        status: file.attributes.status,
                        link: file.attributes.downloadUrl,
                        extension: file.attributes.extension,
                        originalName: file.attributes.originalName
                    };
                }),
                additionalFiles: determination.relationships.mediaFiles.data.filter(file => file.attributes.pivots.type === types_1.DeterminationItemFileTypes.ADDITIONAL).map(file => {
                    return {
                        type: file.attributes.pivots.type,
                        id: file.id,
                        name: file.attributes.name,
                        status: file.attributes.status,
                        link: file.attributes.downloadUrl,
                        extension: file.attributes.extension,
                        originalName: file.attributes.originalName
                    };
                }),
                updatedAt: determination.attributes.updatedAt //TODO @Merab todo should be removed after Tornike migrate database
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseDetermination = parseDetermination;
const parseConditionalSubmissionMediaFiles = (files) => {
    if (!files)
        return [];
    return files.map(file => {
        return {
            id: file.id,
            name: file.attributes.name,
            status: file.attributes.status,
            link: file.attributes.downloadUrl,
            type: file.attributes.pivots.type,
            originalName: file.attributes.originalName,
            extension: file.attributes.extension
        };
    });
};
exports.parseConditionalSubmissionMediaFiles = parseConditionalSubmissionMediaFiles;
const parseConditionalSubmissionFiles = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const conditionalSubmissionFile = response.content.data;
    try {
        return {
            data: (0, parsers_2.parseFileData)(conditionalSubmissionFile),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseConditionalSubmissionFiles = parseConditionalSubmissionFiles;
const parseConditionalSubmissionItemStatus = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const submissionsItems = response.content;
    try {
        return {
            data: submissionsItems.data[0].relationships.submissionItems
                .data.map(el => {
                const submissionItem = el.relationships.verifyItem?.data;
                return {
                    id: el.id,
                    quantity: el.attributes.quantity,
                    procurementStatus: null,
                    deliveryDate: null,
                    purchaseStatus: submissionItem ? submissionItem.attributes.purchaseStatus : types_2.SubmissionVerifyItemStatus.DRAFT,
                    procurementTerminationReason: submissionItem ? submissionItem.attributes.procurementTerminationReason : null,
                    budgetingStatus: submissionItem ? submissionItem.attributes.budgetingStatus : types_2.SubmissionVerifyItemStatus.DRAFT,
                    inPurchase: submissionItem ? submissionItem.attributes.inPurchase : apiGlobalTypes_1.YesNoStatus.NO
                };
            }),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseConditionalSubmissionItemStatus = parseConditionalSubmissionItemStatus;
const parseCreateSubmission = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const submission = response.content.data;
    try {
        return {
            data: {
                submissionId: submission.id
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseCreateSubmission = parseCreateSubmission;
const parseConditionalSubmissionVisualStatuses = (status) => {
    switch (status) {
        case types_1.ConditionalSubmissionsStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.ConditionalSubmissionsStatuses.PROGRESS_IN_PURCHASE:
            return { name: 'შესყიდვის პროცესში', color: 'warning' };
        case types_1.ConditionalSubmissionsStatuses.SENT:
            return { name: 'გაგზავნილი', color: 'primary' };
        case types_1.ConditionalSubmissionsStatuses.FINISHED:
            return { name: 'დასრულებული', color: 'success' };
        case types_1.ConditionalSubmissionsStatuses.DECLINE:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_1.ConditionalSubmissionsStatuses.SUSPEND:
            return { name: 'შეჩერებული', color: 'warning' };
    }
};
const parseDeterminationVisualStatuses = (status) => {
    switch (status) {
        case types_1.DeterminationsStatuses.DRAFT:
            return { name: 'პროექტი', color: 'secondary' };
        case types_1.DeterminationsStatuses.PROGRESS_IN_PURCHASE:
            return { name: 'შესყიდვის პროცესში', color: 'warning' };
        case types_1.DeterminationsStatuses.SENT:
            return { name: 'გაგზავნილი', color: 'primary' };
        case types_1.DeterminationsStatuses.FINISHED:
            return { name: 'დასრულებული', color: 'success' };
        case types_1.DeterminationsStatuses.DECLINE:
            return { name: 'უარყოფილი', color: 'danger' };
        case types_1.DeterminationsStatuses.SUSPEND:
            return { name: 'შეჩერებული', color: 'warning' };
    }
};
const parseConditionalSubmissionVisualSubStatuses = (subStatus) => {
    switch (subStatus) {
        case types_1.ConditionalSubmissionSubStatuses.UNFULFILLED:
            return { name: 'შეუსრულებელი', color: 'secondary' };
        case types_1.ConditionalSubmissionSubStatuses.HALF_DONE:
            return { name: 'ნაწილობრივ შესრულებული', color: 'warning' };
        case types_1.ConditionalSubmissionSubStatuses.FULFILLED:
            return { name: 'შესრულებული', color: 'success' };
    }
};
const parseConditionalSubmissionSentBy = (sentBy) => {
    switch (sentBy) {
        case types_1.ConditionalSubmissionsSentBy.EDOC:
            return { name: 'E-Doc-ით', color: 'primary' };
        case types_1.ConditionalSubmissionsSentBy.CHANCELLERY:
            return { name: 'კანცელარიით', color: 'primary' };
    }
};
