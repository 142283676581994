"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.multiYearReducer = exports.useProcurementMultiYearReducer = exports.initialMultiYearState = exports.initialMultiYearValue = void 0;
const react_1 = require("react");
const parsers_1 = require("api/documents/purchases/procurements/parsers");
const store_1 = require("core/store");
exports.initialMultiYearValue = { multiYearArray: [], procurementYear: null, procurementAmount: null };
exports.initialMultiYearState = {
    data: exports.initialMultiYearValue,
    filterFormValue: { multiYearFilter: parsers_1.PurchaseMultiYearTypes[0] },
    errors: null,
    status: null,
    isCreating: false,
    isDeleting: false,
    meta: store_1.initialMeta,
    isFetching: false,
    isUpdating: null
};
const useProcurementMultiYearReducer = () => {
    return (0, react_1.useReducer)(exports.multiYearReducer, exports.initialMultiYearState);
};
exports.useProcurementMultiYearReducer = useProcurementMultiYearReducer;
const multiYearReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_MULTI_YEAR_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true
            };
        case 'DONE_MULTI_YEAR_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                status: action.payload.status,
                isFetching: false
            };
        case 'SEND_MULTI_YEAR_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: action.payload.id
            };
        case 'DONE_MULTI_YEAR_UPDATE':
            return {
                ...state,
                data: action.payload.data
                    ? {
                        ...state.data,
                        multiYearArray: action.payload.data.multiYearArray
                            ? updatedArray(state.data.multiYearArray, action.payload.data.multiYearArray)
                            : state.data.multiYearArray
                    } : state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: null
            };
        case 'SEND_MULTI_YEAR_CREATE':
            return {
                ...state,
                errors: null,
                isCreating: true
            };
        case 'DONE_MULTI_YEAR_CREATE':
            return {
                ...state,
                data: action.payload.data ? {
                    ...state.data,
                    multiYearArray: [...state.data.multiYearArray, ...action.payload.data.multiYearArray]
                } : state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isCreating: false
            };
        case 'SEND_MULTI_YEAR_DELETE':
            return {
                ...state,
                isDeleting: true
            };
        case 'DONE_MULTI_YEAR_DELETE':
            return {
                ...state,
                data: {
                    ...state.data,
                    multiYearArray: action.payload.errors === null ? state.data.multiYearArray.filter(el => el.id !== action.payload.id) : state.data.multiYearArray
                },
                isDeleting: false,
                errors: action.payload.errors
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'INITIALIZE_FILTER_FORM':
            return {
                ...state,
                filterFormValue: action.payload
            };
        default:
            return state;
    }
};
exports.multiYearReducer = multiYearReducer;
const updatedArray = (state, payload) => {
    return state.map(el => {
        const match = payload.find(item => el.id === item.id);
        return match ? { ...el, ...match } : el;
    });
};
