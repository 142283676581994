"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePurchasedItemsReducer = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
const initialPurchasedItems = {
    data: [],
    meta: store_1.initialMeta,
    errors: null,
    filterForm: {},
    isFetching: false,
    isLoading: false
};
const usePurchasedItemsReducer = () => {
    return (0, react_1.useReducer)(purchasedItemsReducer, initialPurchasedItems);
};
exports.usePurchasedItemsReducer = usePurchasedItemsReducer;
const purchasedItemsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_PURCHASED_ITEMS_REQUEST':
            return {
                ...state,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_PURCHASED_ITEMS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false,
                isLoading: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload.perPage
                    }
                },
                filterForm: action.payload
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
