"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseFundingSourcesForSelect = exports.parseFundingSource = exports.parseFundingSources = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseFundingSources = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const fundingSources = response.content;
    try {
        return {
            data: fundingSources.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    budgetingCheck: !!el.attributes.budgetingCheck,
                    name: el.attributes.name,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(fundingSources.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseFundingSources = parseFundingSources;
const parseFundingSource = (response) => {
    if (response.errors !== null)
        return { data: null, errors: response.errors, status: response.status };
    const fundingSource = response.content;
    try {
        return {
            data: {
                id: fundingSource.data.id,
                budgetingCheck: !!fundingSource.data.attributes.budgetingCheck,
                name: fundingSource.data.attributes.name,
                staff: fundingSource.data.attributes.positionStatus.staff,
                freelance: fundingSource.data.attributes.positionStatus.freelance
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseFundingSource = parseFundingSource;
const parseFundingSourcesForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const fundingSources = response.content;
    try {
        return {
            data: fundingSources.data.map(el => {
                return {
                    id: el.id,
                    label: el.attributes.name,
                    type: el.attributes.type
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseFundingSourcesForSelect = parseFundingSourcesForSelect;
