"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseWorkDirectionsForSelect = exports.parseWorkDirection = exports.parseWorkDirections = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseWorkDirections = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const workDirections = response.content;
    try {
        return {
            data: workDirections.data.map(el => {
                return {
                    id: el.id,
                    creator: el.relationships.creator.data.relationships.user.data.attributes.fullname,
                    structuralUnit: el.relationships.creator.data.relationships.structuralUnit?.data.attributes.fullName,
                    name: el.attributes.name,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(workDirections.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseWorkDirections = parseWorkDirections;
const parseWorkDirection = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const workDirection = response.content;
    try {
        return {
            data: {
                id: workDirection.data.id,
                name: workDirection.data.attributes.name
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseWorkDirection = parseWorkDirection;
const parseWorkDirectionsForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const workDirections = response.content;
    try {
        return {
            data: workDirections.data.map(el => ({
                id: el.id,
                label: el.attributes.name
            })),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseWorkDirectionsForSelect = parseWorkDirectionsForSelect;
