"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const sentEmail_1 = require("../store/sentEmail");
const index_1 = require("api/index");
const useSentEmail = (props) => {
    const [state, dispatch] = (0, sentEmail_1.useSentEmailReducer)();
    const [showSweetAlert, setShowSweetAlert] = (0, react_1.useState)(false);
    const getSentEmail = async (id) => {
        dispatch({ type: 'SEND_SENT_EMAIL_REQUEST' });
        const sentEmail = await index_1.SentEmailsApi.getSentEmail(id);
        dispatch({ type: 'DONE_SENT_EMAIL_REQUEST', payload: sentEmail });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const sendEmail = async (id) => {
        dispatch({ type: 'SEND_EMAIL_REQUEST', payload: id });
        const sentEmail = await index_1.SentEmailsApi.sendEmail(id);
        dispatch({ type: 'DONE_EMAIL_REQUEST', payload: { sentEmailData: sentEmail, id } });
        return sentEmail;
    };
    (0, react_1.useEffect)(() => { if (props.id)
        getSentEmail(props.id).then(); }, []);
    return {
        state,
        closeErrorMessage,
        showSweetAlert,
        setShowSweetAlert,
        sendEmail
    };
};
exports.default = useSentEmail;
