"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const bankInsuranceGuaranteeType_1 = require("src/modules/documents/purchases/procurements/store/bankInsuranceGuaranteeType");
const api_1 = require("src/api");
const helpers_1 = require("src/modules/documents/purchases/procurements/helpers");
const react_1 = require("react");
const useBankInsuranceGuaranteeType = (props) => {
    const [state, dispatch] = (0, bankInsuranceGuaranteeType_1.useBankInsuranceGuaranteeTypeReducer)();
    const updateBankInsuranceGuaranteeType = async (values) => {
        const params = (0, helpers_1.transformBankInsuranceGuaranteeTypeParams)(values);
        dispatch({ type: 'SEND_GUARANTEE_TYPE_REQUEST' });
        const bankInsuranceGuaranteeType = await api_1.ProcurementsApi.updateBankInsuranceGuaranteeType(props.procurementId, params);
        dispatch({ type: 'DONE_GUARANTEE_TYPE_REQUEST', payload: bankInsuranceGuaranteeType });
        return bankInsuranceGuaranteeType;
    };
    const initializeState = () => {
        dispatch({
            type: 'INIT_GUARANTEE_TYPE_STATE',
            payload: {
                bankInsuranceGuaranteeType: props.bankInsuranceGuaranteeType,
                guaranteeDate: props.guaranteeDate,
                guaranteeFiles: props.guaranteeFiles
            }
        });
    };
    (0, react_1.useEffect)(() => {
        initializeState();
    }, [props.bankInsuranceGuaranteeType]);
    return {
        state,
        updateBankInsuranceGuaranteeType
    };
};
exports.default = useBankInsuranceGuaranteeType;
