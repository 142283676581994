"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateFundingSourcesReducerV2 = exports.useCalculateFundingSourcesReducerV2 = void 0;
const react_1 = require("react");
const initialCalculateFundingSourcesData = {
    fundingSources: [],
    confirmedItemsSumPrice: 0,
    declinedItemsSumPrice: 0,
    diffSummery: 0,
    procurementCorrectedPriceSum: 0,
    procurementSumPrice: 0,
    procurementUnusedPriceSum: 0,
    sumPrice: 0,
    suspendedItemsSumPrice: 0
};
const initialCalculateFundingSourcesState = {
    data: initialCalculateFundingSourcesData,
    errors: null,
    isFetching: true,
    status: null
};
const useCalculateFundingSourcesReducerV2 = () => {
    return (0, react_1.useReducer)(exports.calculateFundingSourcesReducerV2, initialCalculateFundingSourcesState);
};
exports.useCalculateFundingSourcesReducerV2 = useCalculateFundingSourcesReducerV2;
const calculateFundingSourcesReducerV2 = (state, action) => {
    switch (action.type) {
        case 'SEND_CALCULATE_FUNDING_SOURCES_V2_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_CALCULATE_FUNDING_SOURCES_V2_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.calculateFundingSourcesReducerV2 = calculateFundingSourcesReducerV2;
