"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.previewFileWithoutPermission = exports.downloadFileWithoutPermission = exports.previewFile = exports.downloadFile = exports.uploadFiles = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/files/parsers");
const uploadFiles = async (formData, url) => {
    const response = await Api.post(url, formData, true);
    return (0, parsers_1.parseMediaFiles)(response);
};
exports.uploadFiles = uploadFiles;
const downloadFile = async (uid) => {
    const response = await Api.get(`media-files/${uid}/download`);
    return (0, parsers_1.parseMediaFileForDownload)(response);
};
exports.downloadFile = downloadFile;
const previewFile = async (uid) => {
    const response = await Api.get(`media-files/${uid}/preview`);
    return (0, parsers_1.parseMediaFileForPreview)(response);
};
exports.previewFile = previewFile;
const downloadFileWithoutPermission = async (uid) => {
    const response = await Api.get(`user/media-files/${uid}/download`);
    return (0, parsers_1.parseMediaFileForDownload)(response);
};
exports.downloadFileWithoutPermission = downloadFileWithoutPermission;
const previewFileWithoutPermission = async (uid) => {
    const response = await Api.get(`user/media-files/${uid}/preview`);
    return (0, parsers_1.parseMediaFileForPreview)(response);
};
exports.previewFileWithoutPermission = previewFileWithoutPermission;
