"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseInventoryItemForSelect = exports.parseInventoryItemsForSelect = exports.parseInventoryItem = exports.parseInventoryItems = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseInventoryItems = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const inventoryItems = response.content;
    try {
        return {
            data: inventoryItems.data.map(el => {
                return {
                    id: el.id,
                    name: el.attributes.name,
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(inventoryItems.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInventoryItems = parseInventoryItems;
const parseInventoryItem = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const inventoryItem = response.content;
    try {
        return {
            data: {
                id: inventoryItem.data.id,
                name: inventoryItem.data.attributes.name
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseInventoryItem = parseInventoryItem;
const parseInventoryItemsForSelect = async (data, errors) => {
    if (data === null)
        return { data: [], errors };
    return {
        data: data.map(el => {
            return {
                id: el.id,
                label: el.name
            };
        }),
        errors: null
    };
};
exports.parseInventoryItemsForSelect = parseInventoryItemsForSelect;
const parseInventoryItemForSelect = async (response) => {
    const responseInventoryItem = response.data;
    return {
        id: responseInventoryItem.id,
        label: responseInventoryItem.attributes.name
    };
};
exports.parseInventoryItemForSelect = parseInventoryItemForSelect;
