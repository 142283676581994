"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insuranceHistoryReducer = exports.useInsuranceHistoryReducer = void 0;
const react_1 = require("react");
const initialInsuranceHistory = {
    data: null,
    isLoading: false,
    errors: null,
    status: null
};
const useInsuranceHistoryReducer = () => {
    return (0, react_1.useReducer)(exports.insuranceHistoryReducer, initialInsuranceHistory);
};
exports.useInsuranceHistoryReducer = useInsuranceHistoryReducer;
const insuranceHistoryReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_HISTORY_REQUEST':
            return {
                ...state,
                errors: null,
                isLoading: true
            };
        case 'DONE_HISTORY_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isLoading: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.insuranceHistoryReducer = insuranceHistoryReducer;
