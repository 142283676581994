"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseEducationalInstitution = exports.parseEducationalInstitutionsForSelect = void 0;
const helper_1 = require("api/helper");
const parseEducationalInstitutionsForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const educationalInstitutions = response.content;
    try {
        return {
            data: educationalInstitutions.data.map(el => {
                return {
                    id: el.id,
                    label: el.attributes.name
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseEducationalInstitutionsForSelect = parseEducationalInstitutionsForSelect;
const parseEducationalInstitution = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const educationalInstitutions = response.content;
    try {
        return {
            data: {
                id: educationalInstitutions.data.id,
                name: educationalInstitutions.data.attributes.name
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseEducationalInstitution = parseEducationalInstitution;
