"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseMajorPositionType = exports.parseMajorPositionTypesForSelect = void 0;
const helper_1 = require("api/helper");
const parseMajorPositionTypesForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const positionTypes = response.content;
    try {
        return {
            data: positionTypes.data.map(el => {
                return {
                    id: el.id,
                    label: el.attributes.name,
                    index: el.attributes.index
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseMajorPositionTypesForSelect = parseMajorPositionTypesForSelect;
const parseMajorPositionType = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const majorPositionType = response.content;
    try {
        return {
            data: {
                id: majorPositionType.data.id,
                nameType: majorPositionType.data.attributes.type,
                name: majorPositionType.data.attributes.name
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMajorPositionType = parseMajorPositionType;
