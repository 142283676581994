"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const signatoryFiles_1 = require("../store/signatoryFiles");
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_1 = require("api/references/employees/types");
const helper_1 = require("../helper");
const react_1 = require("react");
const useSignature = (props) => {
    const [deleteSignatureAlert, setDeleteSignatureAlert] = (0, react_1.useState)({
        showAlert: false,
        id: null
    });
    const [deleteSignatureLoading, setDeleteSignatureLoading] = (0, react_1.useState)([]);
    const [state, dispatch] = (0, signatoryFiles_1.useEmployeeSignatoryFilesReducer)();
    const [open, setOpen] = (0, react_1.useState)(false);
    const getEmployeeActiveSignatoryFiles = async () => {
        dispatch({ type: 'SEND_EMPLOYEE_SIGNATORY_FILES_REQUEST' });
        const employeeSignatoryFiles = await index_1.EmployeesApi.getEmployeeActiveSignatoryFiles(props.userId);
        if (employeeSignatoryFiles.data?.status !== types_1.SignatoryFileStatus.ACTIVE)
            setOpen(true);
        dispatch({ type: 'DONE_EMPLOYEE_SIGNATORY_FILES_REQUEST', payload: employeeSignatoryFiles });
    };
    const createEmployeeSignatoryFiles = async (files) => {
        const params = (0, helper_1.transformEmployeeSignatoryFiles)(files);
        dispatch({ type: 'SEND_EMPLOYEE_SIGNATORY_FILES_CREATE' });
        const EmployeeSignatoryFiles = await index_1.EmployeesApi.createEmployeeSignatoryFiles(props.userId, params);
        const employeeSignatoryFiles = await index_1.EmployeesApi.getEmployeeActiveSignatoryFiles(props.userId);
        dispatch({ type: 'DONE_EMPLOYEE_SIGNATORY_FILES_CREATE', payload: employeeSignatoryFiles });
        if (EmployeeSignatoryFiles.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            if (employeeSignatoryFiles) {
                if (employeeSignatoryFiles.data?.status === types_1.SignatoryFileStatus.ACTIVE)
                    setOpen(false);
            }
        }
        return { status: EmployeeSignatoryFiles.status, errors: EmployeeSignatoryFiles.errors };
    };
    const updateEmployeeSignatoryFiles = async (files) => {
        const params = (0, helper_1.transformEmployeeSignatoryFiles)(files);
        dispatch({ type: 'SEND_EMPLOYEE_SIGNATORY_FILES_UPDATE' });
        const EmployeeSignatoryFiles = await index_1.EmployeesApi.updateEmployeeSignatoryFiles(props.userId, files.pending.id, params);
        const employeeSignatoryFiles = await index_1.EmployeesApi.getEmployeeActiveSignatoryFiles(props.userId);
        dispatch({ type: 'DONE_EMPLOYEE_SIGNATORY_FILES_UPDATE', payload: employeeSignatoryFiles });
        if (EmployeeSignatoryFiles.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            if (employeeSignatoryFiles) {
                if (employeeSignatoryFiles.data?.status === types_1.SignatoryFileStatus.ACTIVE)
                    setOpen(false);
            }
        }
        return { status: EmployeeSignatoryFiles.status, errors: EmployeeSignatoryFiles.errors };
    };
    const deleteEmployeeSignatory = async () => {
        setDeleteSignatureAlert({ ...deleteSignatureAlert, showAlert: false });
        if (deleteSignatureAlert.id)
            setDeleteSignatureLoading(prevState => ([...prevState, deleteSignatureAlert.id]));
        dispatch({ type: 'SEND_EMPLOYEE_DELETE_SIGNATORY' });
        const response = await index_1.EmployeesApi.deleteEmployeeSignatoryFiles(deleteSignatureAlert.id);
        if (response.errors === null) {
            const employeeSignatoryFiles = await index_1.EmployeesApi.getEmployeeActiveSignatoryFiles(props.userId);
            dispatch({ type: 'DONE_EMPLOYEE_SIGNATORY_FILES_CREATE', payload: employeeSignatoryFiles });
        }
        dispatch({
            type: 'DONE_EMPLOYEE_SIGNATORY_FILES_CREATE',
            payload: { data: null, status: response.status, errors: response.errors }
        });
        setDeleteSignatureAlert({ showAlert: false, id: null });
        if (deleteSignatureAlert.id)
            setDeleteSignatureLoading(prevState => prevState.filter(item => item !== deleteSignatureAlert.id));
        return { status: response.status, errors: response.errors };
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { if (props.userId)
        getEmployeeActiveSignatoryFiles().then(); }, [props.userId]);
    return {
        state,
        createEmployeeSignatoryFiles,
        updateEmployeeSignatoryFiles,
        open,
        setOpen,
        deleteSignatureAlert,
        deleteSignatureLoading,
        setDeleteSignatureAlert,
        deleteEmployeeSignatory,
        closeErrorMessage
    };
};
exports.default = useSignature;
