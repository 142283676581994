"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAttributes = void 0;
const index_1 = require("api/index");
const react_1 = require("react");
const useAttributes = (props) => {
    const [companyIsLoading, setCompanyIsLoading] = (0, react_1.useState)(false);
    const [warehouseIsLoading, setWarehouseIsLoading] = (0, react_1.useState)(false);
    const [warehouseOptions, setWarehouseOptions] = (0, react_1.useState)({ data: [], errors: null });
    const getWarehousesForSelect = async () => {
        if (warehouseOptions.data.length > 0)
            return;
        setWarehouseIsLoading(true);
        const res = await index_1.WarehousesApi.getWarehousesForSelect({ perPage: 300 });
        setWarehouseOptions({ data: res.data, errors: res.errors });
        setWarehouseIsLoading(false);
    };
    const handleSelectProcurement = async (procurementId) => {
        setCompanyIsLoading(true);
        const procurement = await index_1.ProcurementsApi.getProcurement(procurementId, true);
        if (procurement.data === null)
            return null;
        const companySelect = {
            id: procurement.data.company.id,
            label: procurement.data.company.label,
            signatories: procurement.data.companySignatories?.map(signatory => {
                return {
                    id: signatory.id,
                    label: signatory.label,
                    phones: signatory.phones?.map(phone => ({ id: phone.id, label: phone.label })) || []
                };
            }) || []
        };
        setCompanyIsLoading(false);
        return companySelect;
    };
    const closeErrorMessage = (index) => {
        setWarehouseOptions(prevState => ({
            ...prevState,
            errors: prevState.errors
                ? prevState.errors.filter((_el, i) => index !== i)
                : prevState.errors
        }));
    };
    (0, react_1.useEffect)(() => {
        if (!props.id)
            getWarehousesForSelect().then();
    }, [props.id]);
    return {
        companyIsLoading,
        warehouseIsLoading,
        getWarehousesForSelect,
        handleSelectProcurement,
        warehouseOptions,
        closeErrorMessage
    };
};
exports.useAttributes = useAttributes;
