"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const index_1 = require("api/index");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const structurulUnitContract_1 = require("../store/structurulUnitContract");
const react_1 = require("react");
const helpers_1 = require("../helpers");
const useAttachDetachUser = (props) => {
    const [state, dispatch] = (0, structurulUnitContract_1.useStructuralUnitContractReducer)();
    const getContract = async (contractId) => {
        dispatch({ type: 'SEND_HR_CONTRACTS_REQUEST' });
        const contract = await index_1.ContractsApi.getContract(contractId, props.structuralUnitId);
        dispatch({ type: 'DONE_HR_CONTRACTS_REQUEST', payload: contract });
    };
    const initialCreateForm = async (structuralUnitId) => {
        dispatch({ type: 'INITIALIZE_CREATE_FORM_REQUEST' });
        const structuralUnit = await index_1.StructuralUnitsUpgradedApi.getAttachInitialInfo(structuralUnitId);
        dispatch({ type: 'INITIALIZE_CREATE_FORM', payload: structuralUnit });
    };
    const createContract = async (values) => {
        const params = (0, helpers_1.userAttachParams)(values);
        dispatch({ type: 'SEND_CREATE_HR_CONTRACTS' });
        const contract = await index_1.ContractsApi.createContract(params, props.structuralUnitId);
        dispatch({ type: 'DONE_CREATE_HR_CONTRACTS', payload: contract });
        return contract;
    };
    const updateContract = async (contractId, values) => {
        const params = (0, helpers_1.userAttachParams)(values);
        dispatch({ type: 'SEND_HR_CONTRACTS_UPDATE' });
        const contract = await index_1.ContractsApi.updateContract(contractId, params, props.structuralUnitId);
        dispatch({ type: 'DONE_HR_CONTRACTS_UPDATE', payload: contract });
        return contract;
    };
    const deleteContract = async (contractId) => {
        dispatch({ type: 'SEND_HR_CONTRACTS_DELETE_REQUEST' });
        const response = await index_1.ContractsApi.deleteContract(contractId);
        dispatch({ type: 'DONE_HR_CONTRACTS_DELETE_REQUEST', payload: { status: response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS } });
        return response;
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        if (props.contractId) {
            getContract(props.contractId).then();
        }
        else {
            if (props.structuralUnitId)
                initialCreateForm(props.structuralUnitId).then();
        }
    }, [props.contractId]);
    return {
        state,
        createContract,
        updateContract,
        deleteContract,
        closeErrorMessage
    };
};
exports.default = useAttachDetachUser;
