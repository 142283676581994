"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const calculateByBudgetArticles_1 = require("src/modules/documents/purchases/submissionVerifies/store/calculateByBudgetArticles");
const api_1 = require("src/api");
const react_1 = require("react");
const useCalculateBudgetArticlesV2 = (props) => {
    const [state, dispatch] = (0, calculateByBudgetArticles_1.useCalculateBudgetArticlesV2Reducer)();
    const [error, setError] = (0, react_1.useState)('');
    const getCalculatedDataByBudgetArticles = async () => {
        dispatch({ type: 'SEND_CALCULATE_BY_BUDGET_ARTICLES' });
        const calculatedByBudgetArticles = await api_1.SubmissionVerifiesApi.getCalculatedByBudgetArticles(props.verifySubmissionId);
        if (props.items.some(el => !el.quantity || !el.price)) {
            setError('გთხოვთ შეავსოთ გამოტოვებული ველები. აუცილებელია ველებია: ოდენობა, ერთეულის ფასი, ვალუტა და გაცვლითი კურსი');
        }
        dispatch({ type: 'DONE_CALCULATE_BY_BUDGET_ARTICLES', payload: calculatedByBudgetArticles });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => { getCalculatedDataByBudgetArticles().then(); }, []);
    return {
        state,
        error,
        closeErrorMessage
    };
};
exports.default = useCalculateBudgetArticlesV2;
