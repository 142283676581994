"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.guaranteeArticleAutocompleteReducer = exports.useGuaranteeArticleAutocompleteReducer = exports.initialGuaranteeArticleAutocompleteState = void 0;
const react_1 = require("react");
exports.initialGuaranteeArticleAutocompleteState = {
    data: [],
    errors: null,
    isFetching: false
};
const useGuaranteeArticleAutocompleteReducer = () => {
    return (0, react_1.useReducer)(exports.guaranteeArticleAutocompleteReducer, exports.initialGuaranteeArticleAutocompleteState);
};
exports.useGuaranteeArticleAutocompleteReducer = useGuaranteeArticleAutocompleteReducer;
const guaranteeArticleAutocompleteReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_GUARANTEE_ARTICLE_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_GUARANTEE_ARTICLE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'RESET_DATA':
            return {
                ...state,
                data: []
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.guaranteeArticleAutocompleteReducer = guaranteeArticleAutocompleteReducer;
