"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleFetchLoadOptions = exports.handleSelectFieldOnFocus = void 0;
const handleSelectFieldOnFocus = (loadOptions, defaultOptions, setLoading, setDefaultOptions, toast, options, forceFetch) => {
    if (!options && defaultOptions.length === 0 || forceFetch) {
        setLoading(true);
        loadOptions('', async (options) => {
            setDefaultOptions(options.data);
            if (options.errors !== null) {
                options.errors.forEach(error => toast.danger(error.message));
            }
            setLoading(false);
        });
    }
};
exports.handleSelectFieldOnFocus = handleSelectFieldOnFocus;
const handleFetchLoadOptions = (inputValue, loadOptions, toast, callback) => {
    loadOptions(inputValue, results => {
        if (results.errors !== null) {
            results.errors.forEach((error) => toast.danger(error.message));
        }
        callback(results.data);
    });
};
exports.handleFetchLoadOptions = handleFetchLoadOptions;
