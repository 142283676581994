"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.paramHeaders = exports.transformUserSettingsParams = exports.getParamsFromUrl = void 0;
const parsers_1 = require("api/notifications/parsers");
const queryString_1 = require("core/helpers/queryString");
const getParamsFromUrl = (url) => {
    try {
        const params = (0, queryString_1.parse)(url);
        return {
            page: params.page,
            perPage: params.perPage,
            filters: params.filters?.unread ? { unread: params.filters.unread } : undefined
        };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.getParamsFromUrl = getParamsFromUrl;
const transformUserSettingsParams = (params) => {
    return {
        locale: params.locale,
        notify: params.settings.map((el, index) => {
            const channelList = [];
            el.channelType.forEach((channel, channelIndex) => {
                if (channel.name === 'SystemChannel') {
                    if (el.notificationType[channel.modifiedName]) {
                        channelList.push(1);
                        channelList.push(el.channelType[channelIndex].key);
                    }
                }
                else {
                    if (el.notificationType[channel.modifiedName])
                        channelList.push(el.channelType[channelIndex].key);
                }
            });
            return {
                className: parsers_1.notifications[index].className,
                channels: channelList
            };
        })
    };
};
exports.transformUserSettingsParams = transformUserSettingsParams;
exports.paramHeaders = {
    0: { header: 'შესყიდვები', subheader: 'პირობითი წარდგინება' },
    6: { subheader: 'წარდგინება' },
    9: { subheader: 'წარდგინების გადამოწმება' },
    15: { subheader: 'შესყიდვის ხელშეკრულება' },
    30: { subheader: 'მიღება-ჩაბარება' },
    37: { header: 'საწყობი' },
    38: { header: 'ადამიანური რესურსები' },
    54: { header: 'ზოგადი' }
};
