"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBudgetArticles = exports.getBudgetArticleForSelect = exports.getBudgetArticlesForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/budgetArticle/parsers");
const getBudgetArticlesForSelect = async (params) => {
    const response = await Api.get('references/budget-articles', params);
    return (0, parsers_1.parseBudgetArticlesForSelect)(response);
};
exports.getBudgetArticlesForSelect = getBudgetArticlesForSelect;
const getBudgetArticleForSelect = async (id) => {
    const response = await Api.get('references/budget-articles/' + id);
    return (0, parsers_1.parseBudgetArticleForSelect)(response);
};
exports.getBudgetArticleForSelect = getBudgetArticleForSelect;
const getBudgetArticles = async (params) => {
    const response = await Api.get('references/budget-articles', params);
    return (0, parsers_1.parseBudgetArticles)(response);
};
exports.getBudgetArticles = getBudgetArticles;
