"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.procurementMultiYearHistoryReducer = exports.useProcurementMultiYearHistoryReducer = exports.initialMultiYearHistoryState = void 0;
const react_1 = require("react");
exports.initialMultiYearHistoryState = {
    data: [],
    status: null,
    isFetching: true,
    errors: null
};
const useProcurementMultiYearHistoryReducer = () => {
    return (0, react_1.useReducer)(exports.procurementMultiYearHistoryReducer, exports.initialMultiYearHistoryState);
};
exports.useProcurementMultiYearHistoryReducer = useProcurementMultiYearHistoryReducer;
const procurementMultiYearHistoryReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_MULTI_YEAR_HISTORY_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_MULTI_YEAR_HISTORY_REQUEST':
            return {
                ...state,
                data: action.payload.data || [],
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false
            };
        default:
            return state;
    }
};
exports.procurementMultiYearHistoryReducer = procurementMultiYearHistoryReducer;
