"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformCompanyFilterParamsToUrl = exports.transformCompanyParamsToFilterParams = exports.transformUrlToCompanyFilterParams = exports.companyAddressTypeOptions = exports.transformCompanyBankAccountParams = exports.transformCompanyAddressParams = exports.transformCompanyResidencyFileParams = exports.transformCompanySignatoryParams = exports.transformCompanyParams = exports.breadCrumb = void 0;
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const types_1 = require("api/references/companies/types");
const queryString_1 = require("core/helpers/queryString");
const lodash_1 = require("lodash");
const breadCrumb = (id) => {
    const addOrEdit = id ? 'განახლება' : 'დამატება';
    return [
        { text: 'ცნობარები - კომპანიები', link: '/references/companies' },
        { text: addOrEdit, link: '/' }
    ];
};
exports.breadCrumb = breadCrumb;
const transformCompanyParams = (values) => {
    return {
        identificationNumber: values.identificationNumber || null,
        name: values.name,
        legalStatus: Number(values.legalStatus.id),
        status: values.status,
        fileUids: values.files.map(el => el.id),
        // resident: values.resident,
        foreignCompany: values.foreignCompany === apiGlobalTypes_1.YesNoStatus.YES,
        isResident: values.isResident === apiGlobalTypes_1.YesNoStatus.YES
    };
};
exports.transformCompanyParams = transformCompanyParams;
const transformCompanySignatoryParams = (data) => {
    const phones = data.phone ? data.phone.split(',') : [];
    return {
        items: [
            {
                firstname: data.firstname,
                lastname: data.lastname,
                position: data.position,
                phones: phones.filter(el => /^\d+$/.test(el)).map(el => ({ phone: (0, lodash_1.trim)(el) }))
            }
        ]
    };
};
exports.transformCompanySignatoryParams = transformCompanySignatoryParams;
const transformCompanyResidencyFileParams = (data) => {
    return {
        id: data.id,
        uid: data.file?.id || null,
        year: String(data.year) || null
    };
};
exports.transformCompanyResidencyFileParams = transformCompanyResidencyFileParams;
const transformCompanyAddressParams = (data) => {
    return {
        items: [
            {
                address: data.address,
                type: data.type.id
            }
        ]
    };
};
exports.transformCompanyAddressParams = transformCompanyAddressParams;
const transformCompanyBankAccountParams = (data) => {
    return {
        items: [
            {
                code: data.code,
                accountNumber: data.accountNumber,
                name: data.name,
                status: data.status
            }
        ]
    };
};
exports.transformCompanyBankAccountParams = transformCompanyBankAccountParams;
exports.companyAddressTypeOptions = [
    { id: types_1.CompanyAddressType.legal, label: 'იურიდიული მისამართი' },
    { id: types_1.CompanyAddressType.physical, label: 'ფაქტობრივი მისამართი' }
];
const transformUrlToCompanyFilterParams = (url) => {
    const values = (0, queryString_1.parse)(url);
    return {
        status: values.statusId && values.statusLabel ? { id: values.statusId, label: values.statusLabel } : undefined,
        keyword: values.keyword,
        page: values.page,
        perPage: values.perPage
    };
};
exports.transformUrlToCompanyFilterParams = transformUrlToCompanyFilterParams;
const transformCompanyParamsToFilterParams = (params) => {
    const filters = {};
    const paginate = {};
    const filterParams = {
        keyword: params.keyword,
        status: params.status?.id,
        page: params.page,
        perPage: params.perPage
    };
    try {
        Object.entries(filterParams).map(([key, value]) => {
            if (value && key !== 'page' && key !== 'perPage')
                filters[key] = value;
            if (value && (key === 'page' || key === 'perPage'))
                paginate[key] = value;
        });
        if (Object.keys(filters).length === 0)
            return { ...paginate };
        return { ...paginate, filters };
    }
    catch (err) {
        console.error({ err });
        return {};
    }
};
exports.transformCompanyParamsToFilterParams = transformCompanyParamsToFilterParams;
const transformCompanyFilterParamsToUrl = (values) => {
    const { page, perPage, keyword, status } = values;
    return {
        page,
        perPage,
        keyword,
        statusId: status?.id,
        statusLabel: status?.label
    };
};
exports.transformCompanyFilterParamsToUrl = transformCompanyFilterParamsToUrl;
