"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubmissionsForCorrection = exports.sendStatusToCorrection = exports.declineExpirationDate = exports.confirmExpirationDate = exports.getConditionalSubmissionItemsForSubmission = exports.deleteSubmissionFile = exports.changeStatus = exports.expirationDateHistory = exports.changeExpirationDate = exports.checkItemStatus = exports.updateSubmissionItem = exports.deleteSubmissionItem = exports.getSubmissionItemsForm = exports.getSubmissionItems = exports.updateSubmission = exports.getSubmission = exports.deleteSubmission = exports.getSubmissions = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/documents/purchases/submissions/parsers");
const getSubmissions = async (params) => {
    const response = await Api.get('documents/purchases/submissions', params);
    return (0, parsers_1.parseSubmissions)(response);
};
exports.getSubmissions = getSubmissions;
const deleteSubmission = async (id) => {
    return await Api.deleteItem('documents/purchases/submissions/' + id);
};
exports.deleteSubmission = deleteSubmission;
const getSubmission = async (id) => {
    const response = await Api.get('documents/purchases/submissions/' + id);
    return (0, parsers_1.parseSubmissionData)(response);
};
exports.getSubmission = getSubmission;
const updateSubmission = async (id, params) => {
    const response = await Api.patch(`documents/purchases/submissions/${id}`, params);
    return (0, parsers_1.parseSubmissionData)(response);
};
exports.updateSubmission = updateSubmission;
const getSubmissionItems = async (id, page) => {
    const response = await Api.get(`documents/purchases/submissions/${id}/items`, { page });
    return (0, parsers_1.parseSubmissionItems)(response);
};
exports.getSubmissionItems = getSubmissionItems;
const getSubmissionItemsForm = async (id, params) => {
    const response = await Api.get(`documents/purchases/submissions/${id}/items`, params);
    return (0, parsers_1.parseSubmissionItemsForm)(response);
};
exports.getSubmissionItemsForm = getSubmissionItemsForm;
const deleteSubmissionItem = async (submissionId, params) => {
    return await Api.deleteItem(`documents/purchases/submissions/${submissionId}/items`, params);
};
exports.deleteSubmissionItem = deleteSubmissionItem;
const updateSubmissionItem = async (id, params) => {
    const response = await Api.patch(`documents/purchases/submissions/${id}/items`, params);
    return (0, parsers_1.parseSubmissionItemsForm)(response);
};
exports.updateSubmissionItem = updateSubmissionItem;
const checkItemStatus = async (submissionId, itemId) => {
    const response = await Api.get(`documents/purchases/submissions/item-info/${submissionId}`, itemId);
    return (0, parsers_1.parseSubmissionItemStatus)(response);
};
exports.checkItemStatus = checkItemStatus;
const changeExpirationDate = async (id, params) => {
    const response = await Api.post('documents/purchases/submissions/change-expiration-date/' + id, params);
    return (0, parsers_1.parseChangeExpirationDate)(response);
};
exports.changeExpirationDate = changeExpirationDate;
const expirationDateHistory = async (id) => {
    const response = await Api.get('documents/purchases/submissions/expiration-date-history/' + id);
    return (0, parsers_1.parseExpirationDateHistory)(response);
};
exports.expirationDateHistory = expirationDateHistory;
const changeStatus = async (id, params) => {
    const response = await Api.patch(`documents/purchases/submissions/change-status/${id}`, params);
    return (0, parsers_1.parseSubmissionData)(response);
};
exports.changeStatus = changeStatus;
const deleteSubmissionFile = async (id, params) => {
    return await Api.patch('documents/purchases/submissions/remove-files/' + id, params);
};
exports.deleteSubmissionFile = deleteSubmissionFile;
const getConditionalSubmissionItemsForSubmission = async (id, params) => {
    const response = await Api.get(`documents/purchases/conditional-submissions/${id}/items`, params);
    return (0, parsers_1.parseConditionalSubmissionItemsForSubmission)(response);
};
exports.getConditionalSubmissionItemsForSubmission = getConditionalSubmissionItemsForSubmission;
const confirmExpirationDate = async (id) => {
    const response = await Api.patch(`documents/purchases/submissions/confirm-expiration-date/${id}`, {});
    return (0, parsers_1.parseExpirationDateData)(response);
};
exports.confirmExpirationDate = confirmExpirationDate;
const declineExpirationDate = async (id, params) => {
    const response = await Api.patch(`documents/purchases/submissions/decline-expiration-date/${id}`, params);
    return (0, parsers_1.parseExpirationDateData)(response);
};
exports.declineExpirationDate = declineExpirationDate;
const sendStatusToCorrection = async (id) => {
    const response = await Api.patch(`documents/purchases/submissions/status-to-correction/${id}`, {});
    return (0, parsers_1.parseSubmissionData)(response);
};
exports.sendStatusToCorrection = sendStatusToCorrection;
const getSubmissionsForCorrection = async (params) => {
    const response = await Api.get('documents/purchases/submissions', params);
    return (0, parsers_1.parseSubmissionsForCorrection)(response);
};
exports.getSubmissionsForCorrection = getSubmissionsForCorrection;
