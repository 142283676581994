"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupedCpvCodesReducerV2 = exports.useGroupedCpvCodesReducerV2 = void 0;
const react_1 = require("react");
const initialGroupedCpvCodesData = {
    id: null,
    cpvCodeItems: [],
    sumPrice: 0
};
const initialGroupedCpvCodesState = {
    data: initialGroupedCpvCodesData,
    status: null,
    errors: null,
    isFetching: true
};
const useGroupedCpvCodesReducerV2 = () => {
    return (0, react_1.useReducer)(exports.groupedCpvCodesReducerV2, initialGroupedCpvCodesState);
};
exports.useGroupedCpvCodesReducerV2 = useGroupedCpvCodesReducerV2;
const groupedCpvCodesReducerV2 = (state, action) => {
    switch (action.type) {
        case 'SEND_GROUPED_CPV_CODES_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_GROUPED_CPV_CODES_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.groupedCpvCodesReducerV2 = groupedCpvCodesReducerV2;
