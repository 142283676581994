"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submissionReducer = exports.useSubmissionReducer = exports.initialSubmissionState = exports.initialSubmissionValue = void 0;
const types_1 = require("api/documents/purchases/submissions/types");
const react_1 = require("react");
// export const initialSubmissionItemsValue: SubmissionItemType = {
//   id: null,
//   purchaseSubject: {
//     subjectName: '',
//     specificationName: '',
//     categoryName: '',
//     verified: true
//   },
//   parentId: null,
//   originalQuantity: null,
//   assignment: '',
//   createdAt: '',
//   updatedAt: '',
//   inventoryConfigurationId: '',
//   characteristics: '',
//   cpvCode: null,
//   currencyExchangeRate: null,
//   currency: { id: '1', label: 'GEL', currentExchangeRate: 1, quantity: 1, transformedRate: 1 },
//   dimensionUnit: null,
//   price: null,
//   project: null,
//   quantity: null,
//   gelSummery: null,
//   conditionalSubmissionItemId: null,
//   templateType: null
// }
exports.initialSubmissionValue = {
    number: '',
    date: '',
    status: types_1.SubmissionsStatuses.DRAFT,
    subStatus: types_1.SubmissionsSubStatuses.PRICE_RESEARCH,
    executionDate: '',
    expirationDate: '',
    createdAt: '',
    updatedAt: '',
    alert: false,
    employee: null,
    structuralUnit: null,
    remainingItemCount: null,
    createdBy: '',
    files: [],
    items: [],
    createdById: null,
    expirationDateId: null,
    expirationDateStatus: null,
    declineComment: null,
    requiredExpirationDate: null,
    comment: null,
    hasForceMajeureComment: false
};
exports.initialSubmissionState = {
    data: exports.initialSubmissionValue,
    status: null,
    errors: null,
    isFetching: false,
    isUpdatingExpirationDate: false,
    isUpdating: false,
    isLoading: false,
    isChangingStatus: false,
    isConfirmingExpirationDate: false,
    isDecliningExpirationDate: false,
    isSendingStatusToCorrection: false
};
const useSubmissionReducer = () => {
    return (0, react_1.useReducer)(exports.submissionReducer, exports.initialSubmissionState);
};
exports.useSubmissionReducer = useSubmissionReducer;
const submissionReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_SUBMISSION_REQUEST':
            return {
                ...state,
                errors: null,
                isFetching: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isFetching: false,
                isLoading: state.isUpdating || state.isChangingStatus
            };
        case 'SEND_SUBMISSION_UPDATE':
            return {
                ...state,
                errors: null,
                isUpdating: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_UPDATE':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isUpdating: false,
                isLoading: state.isFetching || state.isChangingStatus
            };
        case 'SEND_CHANGE_EXPIRATION_DATE_REQUEST':
            return {
                ...state,
                isUpdatingExpirationDate: true
            };
        case 'DONE_CHANGE_EXPIRATION_DATE_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    executionDate: action.payload.data?.executionDate || state.data.executionDate,
                    expirationDate: action.payload.data?.expirationDate || state.data.expirationDate,
                    expirationDateStatus: action.payload.data?.expirationDateStatus || null,
                    expirationDateId: action.payload.data?.expirationDateId || state.data.expirationDateId,
                    comment: action.payload.data?.comment || state.data.comment,
                    requiredExpirationDate: action.payload.data?.requiredExpirationDate || state.data.requiredExpirationDate
                },
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdatingExpirationDate: false
            };
        case 'SEND_CONFIRM_EXPIRATION_DATE_REQUEST':
            return {
                ...state,
                isConfirmingExpirationDate: true
            };
        case 'DONE_CONFIRM_EXPIRATION_DATE_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    expirationDateStatus: action.payload.data?.status || state.data.expirationDateStatus,
                    executionDate: action.payload.data?.executionDate || state.data.executionDate,
                    expirationDate: action.payload.data?.expirationDate || state.data.expirationDate
                },
                isConfirmingExpirationDate: false,
                errors: action.payload.errors,
                status: action.payload.status
            };
        case 'SEND_DECLINE_EXPIRATION_DATE_REQUEST':
            return {
                ...state,
                isDecliningExpirationDate: true
            };
        case 'DONE_DECLINE_EXPIRATION_DATE_REQUEST':
            return {
                ...state,
                data: {
                    ...state.data,
                    declineComment: action.payload.data?.declineComment || state.data.declineComment,
                    expirationDateStatus: action.payload.data?.status || state.data.expirationDateStatus
                },
                isDecliningExpirationDate: false,
                errors: action.payload.errors,
                status: action.payload.status
            };
        case 'SEND_SUBMISSION_CHANGE_STATUS':
            return {
                ...state,
                isChangingStatus: true,
                isLoading: true
            };
        case 'DONE_SUBMISSION_CHANGE_STATUS':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isChangingStatus: false,
                isLoading: state.isFetching || state.isUpdating
            };
        case 'SEND_STATUS_TO_CORRECTION_REQUEST':
            return {
                ...state,
                isSendingStatusToCorrection: true,
                isLoading: true
            };
        case 'DONE_STATUS_TO_CORRECTION_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isSendingStatusToCorrection: false,
                isLoading: state.isFetching || state.isUpdating || state.isChangingStatus
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.submissionReducer = submissionReducer;
