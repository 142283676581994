"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubmissionVerifyItemsV2 = void 0;
const index_1 = require("api/index");
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const submissionVerifyItems_v2_1 = require("../store/submissionVerifyItems-v2");
const logTypes_1 = require("api/documents/purchases/submissionVerifies/logTypes");
const helpers_1 = require("core/helpers");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const helpers_2 = require("../helpers");
const queryString_1 = require("core/helpers/queryString");
const react_router_dom_1 = require("react-router-dom");
const useSubmissionVerifyItemsV2 = (props) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const [keyword, setKeyword] = (0, react_1.useState)('');
    const [verifySubmissionItemType, setVerifySubmissionItemType] = (0, react_1.useState)(logTypes_1.VerifySubmissionItemsTypes[0]);
    const [dimensionUnits, setDimensionUnits] = (0, react_1.useState)([]);
    const [currencies, setCurrencies] = (0, react_1.useState)([]);
    const [isCurrenciesLoading, setIsCurrenciesLoading] = (0, react_1.useState)(false);
    const [deletingItem, setDeletingItem] = (0, react_1.useState)({ deleting: false, index: null });
    const [state, dispatch] = (0, submissionVerifyItems_v2_1.useSubmissionVerifyItemsV2Reducer)();
    const [cascadedItems, setCascadedItems] = (0, react_1.useState)([]);
    const [showDeclineMessage, setShowDeclineMessage] = (0, react_1.useState)(false);
    const [showSuspendMessage, setShowSuspendMessage] = (0, react_1.useState)(false);
    const [showCalculateCpvCodes, setShowCalculateCpvCodes] = (0, react_1.useState)(false);
    const [showCalculateCpvCodesV2, setShowCalculateCpvCodesV2] = (0, react_1.useState)(false);
    const [showCalculateFundingSources, setShowCalculateFundingSources] = (0, react_1.useState)(false);
    const [showCalculateFundingSourcesV2, setShowCalculateFundingSourcesV2] = (0, react_1.useState)(false);
    const [showCalculateBudgetArticles, setShowCalculateBudgetArticles] = (0, react_1.useState)(false);
    const [showCalculateBudgetArticlesV2, setShowCalculateBudgetArticlesV2] = (0, react_1.useState)(false);
    const [dirtyForms, setDirtyForms] = (0, react_1.useState)([]);
    const [isListDirty, setIsListDirty] = (0, react_1.useState)(false);
    const ref = (0, react_1.useRef)(null);
    const [isSticky, setSticky] = (0, react_1.useState)(false);
    const [width, setWidth] = (0, react_1.useState)(undefined);
    const [elRefs, setElRefs] = (0, react_1.useState)([]);
    const [verifySubmissionItem, setVerifySubmissionItem] = (0, react_1.useState)(null);
    const [showChooseSubject, setShowChooseSubject] = (0, react_1.useState)(false);
    const [showProcurementModal, setShowProcurementModal] = (0, react_1.useState)(false);
    const [itemsApproved, setItemsApproved] = (0, react_1.useState)([]);
    const [selectedItemIds, setSelectedItemIds] = (0, react_1.useState)([]);
    const getSubmissionVerifyItemsV2 = async (params) => {
        const itemsParams = (0, helpers_2.transformItemsV2Params)(params);
        const verifyParams = (0, helpers_2.transformVerifyFilterParamsToUrl)((0, helpers_2.transformVerifyUrlToFilterParams)(location.search));
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_ITEMS_V2_REQUEST' });
        const submissionVerifyItemsV2 = await index_1.SubmissionVerifiesApi.getSubmissionVerifyItemsV2(props.id, itemsParams);
        navigate((0, queryString_1.stringify)({ ...params, ...verifyParams }));
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_ITEMS_V2_REQUEST', payload: submissionVerifyItemsV2 });
    };
    const selectPerPage = (itemPerPage) => {
        const params = (0, queryString_1.parse)(location.search);
        dispatch({ type: 'CHANGE_PER_PAGE', payload: itemPerPage });
        getSubmissionVerifyItemsV2({ ...params, itemPerPage, itemPage: undefined }).then();
    };
    const handleCascadeItem = (id) => {
        cascadedItems.includes(id)
            ? setCascadedItems(cascadedItems.filter(el => el !== id))
            : setCascadedItems([...cascadedItems, id]);
    };
    const deleteVerifySubmissionItem = async (itemId) => {
        setDeletingItem({ ...deletingItem, deleting: true });
        const response = await index_1.SubmissionVerifiesApi.deleteSubmissionVerifyItem(props.id, { ids: [itemId] });
        const itemPage = (0, helpers_1.calculateCurrentPage)(state.meta.pagination, 1);
        if (itemPage && response.status === apiGlobalTypes_1.ResponseStatuses.SUCCESS) {
            const params = (0, queryString_1.parse)(location.search);
            getSubmissionVerifyItemsV2({ ...params, itemPage }).then();
        }
        else {
            dispatch({ type: 'DONE_SUBMISSION_VERIFY_ITEM_DELETE_REQUEST', payload: { errors: response.errors, deleteItemLength: 1, deleteItemId: itemId } }); //1 is a length of an array
        }
        setDeletingItem({ index: null, deleting: false });
        return { errors: response.errors, status: response.status };
    };
    const updateVerifySubmissionItemsList = async (id, values, actionType) => {
        const params = (0, helpers_2.transformVerifySubmissionItemsListParams)(values, actionType);
        const newItemsLength = values.filter(item => item.id === null).length;
        dispatch({ type: 'SEND_ITEMS_LIST_UPDATE_REQUEST' });
        const submissionVerifyItemsV2 = await index_1.SubmissionVerifiesApi.updateVerifySubmissionItem(id, params);
        dispatch({ type: 'DONE_ITEMS_LIST_UPDATE_REQUEST', payload: { submissionVerifyItemsV2, newItemsLength } });
        if (submissionVerifyItemsV2.data !== null)
            setIsListDirty(false);
        return submissionVerifyItemsV2;
    };
    const handleDivideSubmissionVerifyItem = (item, items, itemIndex) => {
        const newValue = {
            ...item,
            id: null,
            parentId: item.parentId ?? item.id,
            quantity: 0
        };
        const newArray = [...items];
        newArray.splice(itemIndex + 1, 0, newValue);
        return newArray;
    };
    const debouncedSearch = (value) => {
        setKeyword(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = value
            ? { perPage: searchParams.perPage, filters: { keyword: value, withDeleted: searchParams.filters?.withDeleted, onlyDeleted: searchParams.filters?.onlyDeleted } }
            : { page: searchParams.page, filters: { withDeleted: searchParams.filters?.withDeleted, onlyDeleted: searchParams.filters?.onlyDeleted } };
        (0, debouncedMethods_1.debouncedOnFunc)(params, getSubmissionVerifyItemsV2);
    };
    const handleItemsStatusChange = (_, value) => {
        if (!value)
            return;
        setVerifySubmissionItemType(value);
        const searchParams = (0, queryString_1.parse)(location.search);
        const params = (0, helpers_2.transformSearchParams)(searchParams, value);
        getSubmissionVerifyItemsV2(params).then();
    };
    const navigatePagination = (itemPage) => {
        const params = (0, queryString_1.parse)(location.search);
        getSubmissionVerifyItemsV2({ ...params, itemPage }).then();
    };
    const getDimensionUnitsForSelect = async () => {
        const data = await index_1.DimensionUnitsApi.getDimensionUnitsForSelect({ perPage: 300 });
        setDimensionUnits(data);
    };
    const getCurrenciesForSelect = async (params) => {
        setIsCurrenciesLoading(true);
        const data = await index_1.CurrenciesApi.getCurrencyForSelect(params);
        setCurrencies(data);
        setIsCurrenciesLoading(false);
    };
    const approveItems = async () => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_APPROVE_ITEMS' });
        const submissionVerify = await index_1.SubmissionVerifiesApi.approveItems({ itemIds: selectedItemIds });
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_APPROVE_ITEMS',
            payload: { data: submissionVerify.data,
                errors: submissionVerify.errors, meta: state.meta, status: submissionVerify.status }
        });
        if (submissionVerify.data !== null)
            setSelectedItemIds([]);
        return submissionVerify;
    };
    const updateStateItems = (items) => {
        dispatch({ type: 'UPDATE_STATE_ITEMS', payload: items });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    const declineItems = async (declineComment) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_DECLINE_ITEMS' });
        const itemsV2 = await index_1.SubmissionVerifiesApi.declineItems({ declineComment, itemIds: selectedItemIds });
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_DECLINE_ITEMS',
            payload: { data: itemsV2.data,
                errors: itemsV2.errors, meta: state.meta, status: itemsV2.status }
        });
        if (itemsV2.data !== null)
            setSelectedItemIds([]);
        return itemsV2;
    };
    const suspendItems = async (suspendComment) => {
        dispatch({ type: 'SEND_SUBMISSION_VERIFY_SUSPEND_ITEMS' });
        const submissionVerify = await index_1.SubmissionVerifiesApi.suspendItems({ suspendComment, itemIds: selectedItemIds });
        dispatch({ type: 'DONE_SUBMISSION_VERIFY_SUSPEND_ITEMS',
            payload: { data: submissionVerify.data,
                errors: submissionVerify.errors, meta: state.meta, status: submissionVerify.status }
        });
        if (submissionVerify.data !== null)
            setSelectedItemIds([]);
        return submissionVerify;
    };
    const handleScroll = () => {
        if (ref.current) {
            setWidth(ref.current.clientWidth);
            setSticky(ref.current.getBoundingClientRect().top <= 122);
        }
    };
    const checkVerifySubmissionDirtiness = (index, dirty) => {
        dirty ? setDirtyForms(prev => ([...prev, index])) : setDirtyForms(prev => prev.filter(el => el !== index));
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);
    (0, react_1.useEffect)(() => {
        getDimensionUnitsForSelect().then();
        // getCurrenciesForSelect({ perPage: 300, sort: '+sort,-id' }).then()
    }, []);
    (0, react_1.useEffect)(() => {
        const params = (0, helpers_2.getItemsV2ParamsFromUrl)(location.search);
        const paginatedStorage = JSON.parse(localStorage.getItem('paginated') || '""');
        const storagePerPage = paginatedStorage?.verifySubmissionItems?.perPage;
        params.itemPerPage = params.itemPerPage || Number(storagePerPage) || 50;
        setKeyword(params.itemKeyword || '');
        setVerifySubmissionItemType(params.itemWithDeleted
            ? logTypes_1.VerifySubmissionItemsTypes.find(el => Number(el.id) === logTypes_1.VerifySubmissionItemsType.WITH_DELETED)
            : params.itemOnlyDeleted
                ? logTypes_1.VerifySubmissionItemsTypes.find(el => Number(el.id) === logTypes_1.VerifySubmissionItemsType.ONLY_DELETED)
                : logTypes_1.VerifySubmissionItemsTypes.find(el => Number(el.id) === logTypes_1.VerifySubmissionItemsType.WITHOUT_DELETED));
        getSubmissionVerifyItemsV2(params).then();
    }, [props.id]);
    return {
        state,
        selectPerPage,
        keyword,
        debouncedSearch,
        navigatePagination,
        updateStateItems,
        setCascadedItems,
        cascadedItems,
        handleCascadeItem,
        dimensionUnits,
        currencies,
        deleteVerifySubmissionItem,
        deletingItem,
        setDeletingItem,
        approveItems,
        setShowDeclineMessage,
        showDeclineMessage,
        declineItems,
        setShowSuspendMessage,
        showSuspendMessage,
        suspendItems,
        setShowCalculateFundingSources,
        showCalculateFundingSources,
        showCalculateBudgetArticles,
        setShowCalculateBudgetArticles,
        isSticky,
        ref,
        width,
        updateVerifySubmissionItemsList,
        setElRefs,
        elRefs,
        isListDirty,
        setIsListDirty,
        setDirtyForms,
        dirtyForms,
        checkVerifySubmissionDirtiness,
        setVerifySubmissionItem,
        verifySubmissionItem,
        setShowCalculateCpvCodes,
        showCalculateCpvCodes,
        showCalculateCpvCodesV2,
        setShowCalculateCpvCodesV2,
        verifySubmissionItemType,
        handleItemsStatusChange,
        setShowChooseSubject,
        showChooseSubject,
        setShowProcurementModal,
        showProcurementModal,
        itemsApproved,
        setItemsApproved,
        handleDivideSubmissionVerifyItem,
        selectedItemIds,
        setSelectedItemIds,
        getCurrenciesForSelect,
        isCurrenciesLoading,
        closeErrorMessage,
        setShowCalculateBudgetArticlesV2,
        showCalculateBudgetArticlesV2,
        setShowCalculateFundingSourcesV2,
        showCalculateFundingSourcesV2
    };
};
exports.useSubmissionVerifyItemsV2 = useSubmissionVerifyItemsV2;
