"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bankInsuranceGuaranteeType = exports.useBankInsuranceGuaranteeTypeReducer = exports.initialGuaranteeType = void 0;
const react_1 = require("react");
exports.initialGuaranteeType = {
    bankInsuranceGuaranteeType: null,
    guaranteeDate: null,
    guaranteeFiles: []
};
const initialBankInsuranceGuaranteeState = {
    data: exports.initialGuaranteeType,
    isUpdating: false,
    errors: null,
    status: null
};
const useBankInsuranceGuaranteeTypeReducer = () => {
    return (0, react_1.useReducer)(exports.bankInsuranceGuaranteeType, initialBankInsuranceGuaranteeState);
};
exports.useBankInsuranceGuaranteeTypeReducer = useBankInsuranceGuaranteeTypeReducer;
const bankInsuranceGuaranteeType = (state, action) => {
    switch (action.type) {
        case 'SEND_GUARANTEE_TYPE_REQUEST':
            return {
                ...state,
                errors: null,
                isUpdating: true
            };
        case 'DONE_GUARANTEE_TYPE_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors,
                status: action.payload.status,
                isUpdating: false
            };
        case 'INIT_GUARANTEE_TYPE_STATE':
            return {
                ...state,
                data: {
                    bankInsuranceGuaranteeType: action.payload.bankInsuranceGuaranteeType,
                    guaranteeDate: action.payload.guaranteeDate,
                    guaranteeFiles: action.payload.guaranteeFiles
                }
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.bankInsuranceGuaranteeType = bankInsuranceGuaranteeType;
