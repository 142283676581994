"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInsuranceHistory = void 0;
const insuranceHistory_1 = require("src/modules/documents/purchases/procurements/store/insuranceHistory");
const api_1 = require("src/api");
const react_1 = require("react");
const useInsuranceHistory = (id) => {
    const [state, dispatch] = (0, insuranceHistory_1.useInsuranceHistoryReducer)();
    const getProcurementInsuranceHistory = async () => {
        dispatch({ type: 'SEND_HISTORY_REQUEST' });
        const response = await api_1.ProcurementsApi.getInsuranceHistory(id);
        dispatch({ type: 'DONE_HISTORY_REQUEST', payload: response });
    };
    (0, react_1.useEffect)(() => {
        getProcurementInsuranceHistory().then();
    }, []);
    return { state };
};
exports.useInsuranceHistory = useInsuranceHistory;
