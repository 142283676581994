"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBanksForSelect = exports.parseBank = exports.parseMediatorBanksData = exports.parseMediatorBanks = exports.parseBanks = void 0;
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parseBanks = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const banks = response.content;
    try {
        return {
            data: banks.data.map(el => parseBankData(el)),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(banks.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseBanks = parseBanks;
const parseMediatorBanks = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const mediatorBanks = response.content;
    try {
        return {
            data: mediatorBanks.data.map(el => parseBankData(el)),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMediatorBanks = parseMediatorBanks;
const parseMediatorBanksData = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const mediatorBanks = response.content;
    try {
        return {
            data: mediatorBanks.data.relationships.mediatorBanks.data.map(el => parseBankData(el)),
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseMediatorBanksData = parseMediatorBanksData;
const parseBank = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const bank = response.content;
    try {
        return {
            data: {
                id: bank.data.id,
                title: bank.data.attributes.title,
                code: bank.data.attributes.code,
                iban: bank.data.attributes.iban,
                type: bank.data.attributes.type
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parseBank = parseBank;
const parseBankData = (bank) => {
    return {
        id: bank.id,
        moduleType: bank.type,
        type: bank.attributes.type,
        title: bank.attributes.title,
        code: bank.attributes.code,
        iban: bank.attributes.iban,
        createdAt: (0, parsers_1.transformDate)(bank.attributes.createdAt),
        createdDateTimeAt: (0, parsers_1.transformDateTime)(bank.attributes.createdAt),
        updatedAt: (0, parsers_1.transformDate)(bank.attributes.updatedAt),
        updatedDateTimeAt: (0, parsers_1.transformDateTime)(bank.attributes.updatedAt),
        isAdded: false
    };
};
const parseBanksForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const banks = response.content;
    try {
        return {
            data: banks.data.map(el => ({
                id: el.id,
                label: el.attributes.title + ' - ' + ' ( ' + el.attributes.code + ' ) ',
                code: el.attributes.code,
                title: el.attributes.title,
                iban: el.attributes.iban,
                type: el.attributes.type
            })),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parseBanksForSelect = parseBanksForSelect;
