"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateByBudgedArticlesV2Reducer = exports.useCalculateBudgetArticlesV2Reducer = exports.initialCalculateByBudgetArticlesV2State = void 0;
const react_1 = require("react");
const initialCalculateByBudgetArticlesV2Data = {
    id: null,
    budgetArticleItems: [],
    sumPrice: 0
};
exports.initialCalculateByBudgetArticlesV2State = {
    data: initialCalculateByBudgetArticlesV2Data,
    errors: null,
    status: null,
    isLoading: false
};
const useCalculateBudgetArticlesV2Reducer = () => {
    return (0, react_1.useReducer)(exports.calculateByBudgedArticlesV2Reducer, exports.initialCalculateByBudgetArticlesV2State);
};
exports.useCalculateBudgetArticlesV2Reducer = useCalculateBudgetArticlesV2Reducer;
const calculateByBudgedArticlesV2Reducer = (state, action) => {
    switch (action.type) {
        case 'SEND_CALCULATE_BY_BUDGET_ARTICLES':
            return {
                ...state,
                isLoading: true
            };
        case 'DONE_CALCULATE_BY_BUDGET_ARTICLES':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                errors: action.payload.errors,
                isLoading: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
exports.calculateByBudgedArticlesV2Reducer = calculateByBudgedArticlesV2Reducer;
