"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementMultiYear = void 0;
const react_1 = require("react");
const procurementMultiYear_1 = require("src/modules/documents/purchases/procurements/store/procurementMultiYear");
const api_1 = require("src/api");
const helpers_1 = require("src/modules/documents/purchases/procurements/helpers");
const useProcurementMultiYear = (props) => {
    const [state, dispatch] = (0, procurementMultiYear_1.useProcurementMultiYearReducer)();
    const [deleteAlert, setDeleteAlert] = (0, react_1.useState)({ showAlert: false, id: null });
    const createProcurementMultiYear = async (id, values) => {
        const params = (0, helpers_1.transformCreateMultiYearParams)(id, values);
        dispatch({ type: 'SEND_MULTI_YEAR_CREATE' });
        const response = await api_1.ProcurementsApi.createOrUpdateProcurementMultiYear(props.procurementId, params);
        dispatch({ type: 'DONE_MULTI_YEAR_CREATE', payload: response });
        return response;
    };
    const updateProcurementMultiYear = async (id, values) => {
        const params = (0, helpers_1.transformCreateMultiYearParams)(id, values);
        dispatch({ type: 'SEND_MULTI_YEAR_UPDATE', payload: { id } });
        const response = await api_1.ProcurementsApi.createOrUpdateProcurementMultiYear(props.procurementId, params);
        dispatch({ type: 'DONE_MULTI_YEAR_UPDATE', payload: response });
        return response;
    };
    const getProcurementMultiYear = async (values) => {
        const filterParams = (0, helpers_1.transformParamsToFilterParams)(values);
        dispatch({ type: 'SEND_MULTI_YEAR_REQUEST' });
        const response = await api_1.ProcurementsApi.getProcurementMultiYear(props.procurementId, filterParams);
        dispatch({ type: 'DONE_MULTI_YEAR_REQUEST', payload: response });
    };
    const initializeFilterForm = async () => {
        dispatch({ type: 'INITIALIZE_FILTER_FORM', payload: { page: 1, perPage: 25 } });
        await getProcurementMultiYear({ page: 1, perPage: 25 });
    };
    const deleteProcurementMultiYear = async (confirm) => {
        if (!confirm || !deleteAlert.id)
            return setDeleteAlert({ showAlert: false, id: null });
        setDeleteAlert({ ...deleteAlert, showAlert: false });
        dispatch({ type: 'SEND_MULTI_YEAR_DELETE' });
        const response = await api_1.ProcurementsApi.deleteProcurementMultiYear(deleteAlert.id);
        dispatch({ type: 'DONE_MULTI_YEAR_DELETE', payload: { id: deleteAlert.id, errors: response.errors } });
        setDeleteAlert({ showAlert: false, id: null });
        return response;
    };
    const navigatePagination = (page, filterValues) => {
        getProcurementMultiYear({ ...filterValues, page }).then();
    };
    const filter = (name, value, filterValues) => {
        getProcurementMultiYear({ ...filterValues, [name]: value, page: 1 }).then();
    };
    const selectPerPage = (perPage, filterValues) => {
        getProcurementMultiYear({ ...filterValues, perPage, page: 1 }).then();
    };
    (0, react_1.useEffect)(() => {
        initializeFilterForm().then();
    }, [props.procurementId]);
    return {
        state,
        createProcurementMultiYear,
        deleteProcurementMultiYear,
        updateProcurementMultiYear,
        setDeleteAlert,
        deleteAlert,
        navigatePagination,
        selectPerPage,
        getProcurementMultiYear,
        filter
    };
};
exports.useProcurementMultiYear = useProcurementMultiYear;
