"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCorrectionCalculationsV2 = void 0;
const correctionCalculationsV2_1 = require("src/modules/documents/purchases/procurementCorrections/store/correctionCalculationsV2");
const api_1 = require("src/api");
const react_1 = require("react");
const useCorrectionCalculationsV2 = (procurementCorrectionId) => {
    const [state, dispatch] = (0, correctionCalculationsV2_1.useCorrectionCalculationsReducerV2)();
    const getProcurementCalculations = async () => {
        dispatch({ type: 'SEND_CORRECTION_CALCULATIONS_V2_REQUEST' });
        const response = await api_1.ProcurementsApi.getCorrectionCalculationsV2(procurementCorrectionId);
        dispatch({ type: 'DONE_CORRECTION_CALCULATIONS_V2_REQUEST', payload: response });
    };
    const closeErrorMessage = (index) => {
        dispatch({ type: 'REMOVE_ERROR', payload: index });
    };
    (0, react_1.useEffect)(() => {
        getProcurementCalculations().then();
    }, []);
    return { state, closeErrorMessage };
};
exports.useCorrectionCalculationsV2 = useCorrectionCalculationsV2;
