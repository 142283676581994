"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getGuaranteeArticlesForSelect = exports.updateGuaranteeArticle = exports.createGuaranteeArticle = exports.getGuaranteeArticle = exports.deleteItem = exports.getGuaranteeArticles = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/guaranteeArticles/parsers");
const getGuaranteeArticles = async (params) => {
    const response = await Api.get('references/guarantee-articles', params);
    return (0, parsers_1.parseGuaranteeArticles)(response);
};
exports.getGuaranteeArticles = getGuaranteeArticles;
const deleteItem = async (id) => {
    return await Api.deleteItem(`references/guarantee-articles/${id}`);
};
exports.deleteItem = deleteItem;
const getGuaranteeArticle = async (id) => {
    const response = await Api.get('references/guarantee-articles/' + id);
    return (0, parsers_1.parseGuaranteeArticle)(response);
};
exports.getGuaranteeArticle = getGuaranteeArticle;
const createGuaranteeArticle = async (params) => {
    const response = await Api.post('references/guarantee-articles', params);
    return (0, parsers_1.parseGuaranteeArticle)(response);
};
exports.createGuaranteeArticle = createGuaranteeArticle;
const updateGuaranteeArticle = async (id, params) => {
    const response = await Api.put('references/guarantee-articles/' + id, params);
    return (0, parsers_1.parseGuaranteeArticle)(response);
};
exports.updateGuaranteeArticle = updateGuaranteeArticle;
const getGuaranteeArticlesForSelect = async (params) => {
    const response = await Api.get('references/guarantee-articles', params);
    return (0, parsers_1.parseGuaranteeArticlesForSelect)(response);
};
exports.getGuaranteeArticlesForSelect = getGuaranteeArticlesForSelect;
