"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRegionsForSelect = exports.updateRegion = exports.createRegion = exports.getRegion = exports.getRegions = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("../../../privateRequest"));
const parsers_1 = require("api/references/addresses/regions/parsers");
const getRegions = async (params) => {
    const response = await Api.get('references/geography/regions', params);
    return (0, parsers_1.parseRegions)(response);
};
exports.getRegions = getRegions;
const getRegion = async (id) => {
    const response = await Api.get('references/geography/regions/' + id);
    return (0, parsers_1.parseRegion)(response);
};
exports.getRegion = getRegion;
const createRegion = async (params) => {
    const response = await Api.post('references/geography/regions', params);
    return (0, parsers_1.parseRegion)(response);
};
exports.createRegion = createRegion;
const updateRegion = async (id, params) => {
    const response = await Api.patch('references/geography/regions/' + id, params);
    return (0, parsers_1.parseRegion)(response);
};
exports.updateRegion = updateRegion;
const getRegionsForSelect = async (params) => {
    const response = await Api.get('references/geography/regions', params);
    return (0, parsers_1.parseRegionsForSelect)(response);
};
exports.getRegionsForSelect = getRegionsForSelect;
