"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useProcurementCalculateItemsV2Reducer = void 0;
const react_1 = require("react");
const initialProcurementCalculateItemsV2Store = {
    data: {
        fundingSources: [],
        submissionHeaders: [],
        sumPrice: 0,
        submissionsSum: [],
        correctedSumPrice: 0,
        usedInDifferentProcurementSumPrice: 0,
        unusedSumPrice: 0
    },
    errors: null,
    isFetching: true
};
const useProcurementCalculateItemsV2Reducer = () => {
    return (0, react_1.useReducer)(ProcurementCalculateItemsReducerV2, initialProcurementCalculateItemsV2Store);
};
exports.useProcurementCalculateItemsV2Reducer = useProcurementCalculateItemsV2Reducer;
const ProcurementCalculateItemsReducerV2 = (state, action) => {
    switch (action.type) {
        case 'SEND_PROCUREMENT_CALCULATE_ITEMS_V2_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_PROCUREMENT_CALCULATE_ITEMS_V2_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                errors: action.payload.errors || null,
                isFetching: false
            };
        case 'REMOVE_ERROR':
            return {
                ...state,
                errors: state.errors ? state.errors.filter((_el, index) => action.payload !== index) : state.errors
            };
        default:
            return state;
    }
};
