"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const apiGlobalTypes_1 = require("api/types/apiGlobalTypes");
const index_1 = require("api/index");
const debouncedMethods_1 = require("api/helper/debouncedMethods");
const usePositionInfo = () => {
    const [workDirectionData, setWorkDirectionData] = (0, react_1.useState)([]);
    const [creatingWorkDirections, setCreatingWorkDirections] = (0, react_1.useState)(false);
    const [workDirectionLoading, setWorkDirectionLoading] = (0, react_1.useState)(false);
    const [onCreatable, setOnCreatable] = (0, react_1.useState)(false);
    const getWorkDirectionAutocomplete = async (params) => {
        setWorkDirectionLoading(true);
        const workDirection = await index_1.WorkDirectionApi.getWorkDirectionsForSelect(params);
        setWorkDirectionLoading(false);
        setWorkDirectionData(workDirection.data);
        setOnCreatable(workDirection.data.every(el => el.label.trim() !== params.filters?.keyword?.trim()));
        return workDirection;
    };
    const handleCreateWorkDirectionsVerify = async (value) => {
        if (!value)
            return { data: null, errors: [{ message: 'მონაცემები არ არის', name: 'empty value' }], status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED };
        if (workDirectionData.some(el => el.label === value)) {
            return { data: null, errors: [{ message: 'მონაცემი უკვე არსებობს', name: 'empty value' }], status: apiGlobalTypes_1.ResponseStatuses.UNEXPECTED
            };
        }
        setCreatingWorkDirections(true);
        const workDirection = await index_1.WorkDirectionApi.createWorkDirection({ name: value });
        setCreatingWorkDirections(false);
        return workDirection;
    };
    const debouncedSearch = async (value) => {
        setOnCreatable(false);
        const params = value ? ({ filters: { keyword: value } }) : {};
        (0, debouncedMethods_1.debouncedOnFunc)(params, getWorkDirectionAutocomplete);
    };
    return {
        debouncedSearch,
        workDirectionData,
        workDirectionLoading,
        setWorkDirectionData,
        handleCreateWorkDirectionsVerify,
        creatingWorkDirections,
        onCreatable
    };
};
exports.default = usePositionInfo;
