"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.notificationsReducer = exports.useNotificationsReducer = exports.initialNotificationFormValue = exports.initialNotificationsState = void 0;
const react_1 = require("react");
const store_1 = require("core/store");
exports.initialNotificationsState = {
    data: { notifications: [], unreadQuantity: null },
    status: null,
    loadingItems: [],
    meta: store_1.initialMeta,
    errors: null,
    isFetching: false,
    isUnreadFetching: false,
    isAllReading: false
};
exports.initialNotificationFormValue = { onlyUnread: false };
const useNotificationsReducer = () => {
    return (0, react_1.useReducer)(exports.notificationsReducer, exports.initialNotificationsState);
};
exports.useNotificationsReducer = useNotificationsReducer;
const notificationsReducer = (state, action) => {
    switch (action.type) {
        case 'SEND_NOTIFICATIONS_REQUEST':
            return {
                ...state,
                isFetching: true
            };
        case 'DONE_NOTIFICATIONS_REQUEST':
            return {
                ...state,
                data: { notifications: action.payload.data?.notifications, unreadQuantity: null } || { notifications: [], unreadQuantity: null },
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta,
                isFetching: false
            };
        case 'GET_NOTIFICATIONS_REQUEST':
            return {
                ...state,
                data: { notifications: action.payload.data?.notifications, unreadQuantity: null } || { notifications: [], unreadQuantity: null },
                status: action.payload.status,
                errors: action.payload.errors,
                meta: action.payload.meta || store_1.initialMeta
            };
        case 'SEND_UNREAD_NOTIFICATIONS_REQUEST':
            return {
                ...state,
                isUnreadFetching: true
            };
        case 'DONE_UNREAD_NOTIFICATIONS_REQUEST':
            return {
                ...state,
                data: action.payload.data || state.data,
                status: action.payload.status,
                meta: action.payload.meta || store_1.initialMeta,
                isUnreadFetching: false
            };
        case 'CHANGE_PER_PAGE':
            return {
                ...state,
                meta: {
                    ...state.meta,
                    pagination: {
                        ...state.meta.pagination,
                        perPage: action.payload
                    }
                }
            };
        case 'SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: [...state.loadingItems, action.payload]
            };
        case 'UN_SET_LOADING_ITEM':
            return {
                ...state,
                loadingItems: state.loadingItems.filter(id => !(id === action.payload.id))
            };
        case 'SEND_SET_ALL_NOTIFICATIONS_AS_READ':
            return {
                ...state,
                isAllReading: true
            };
        case 'DONE_SET_ALL_NOTIFICATIONS_AS_READ':
            return {
                ...state,
                isAllReading: false
            };
        default:
            return state;
    }
};
exports.notificationsReducer = notificationsReducer;
