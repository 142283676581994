"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBudgetSourcesForSelect = exports.updateBudgetSource = exports.createBudgetSource = exports.getBudgetSource = exports.deleteBudgetSource = exports.getBudgetSources = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/funding/budget-sources/parsers");
const getBudgetSources = async (params) => {
    const response = await Api.get('references/funding/budget-sources', params);
    return (0, parsers_1.parseBudgetSources)(response);
};
exports.getBudgetSources = getBudgetSources;
const deleteBudgetSource = async (id) => {
    return await Api.deleteItem('references/funding/budget-sources/' + id);
};
exports.deleteBudgetSource = deleteBudgetSource;
const getBudgetSource = async (id) => {
    const response = await Api.get('references/funding/budget-sources/' + id);
    return (0, parsers_1.parseBudgetSource)(response);
};
exports.getBudgetSource = getBudgetSource;
const createBudgetSource = async (params) => {
    const response = await Api.post('references/funding/budget-sources', params);
    return (0, parsers_1.parseBudgetSource)(response);
};
exports.createBudgetSource = createBudgetSource;
const updateBudgetSource = async (id, params) => {
    const response = await Api.patch('references/funding/budget-sources/' + id, params);
    return (0, parsers_1.parseBudgetSource)((response));
};
exports.updateBudgetSource = updateBudgetSource;
const getBudgetSourcesForSelect = async (params) => {
    const response = await Api.get('references/funding/budget-sources', params);
    return (0, parsers_1.parseBudgetSourcesForSelect)(response);
};
exports.getBudgetSourcesForSelect = getBudgetSourcesForSelect;
