"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parsePurchaseSubjectCollection = exports.parsePurchaseSubjectCharacteristics = exports.parsePurchaseSubject = exports.parsePurchaseSubjectsForSelect = exports.parsePurchaseSubjects = void 0;
const types_1 = require("./types");
const helper_1 = require("api/helper");
const parsers_1 = require("api/parsers/parsers");
const parsePurchaseSubjects = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const purchaseSubjects = response.content;
    try {
        return {
            data: purchaseSubjects.data.map(el => {
                return {
                    id: el.id,
                    moduleType: el.type,
                    subjectName: el.attributes.itemTitle,
                    name: el.attributes.itemTitle,
                    verified: el.attributes.verified,
                    unverifiedClassName: !el.attributes.verified ? 'bg-warning-0-15' : '',
                    specificationName: el.attributes.specificationTitle || '',
                    categoryName: el.attributes.categoryTitle || '',
                    createdAt: (0, parsers_1.transformDate)(el.attributes.createdAt),
                    createdDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.createdAt),
                    updatedAt: (0, parsers_1.transformDate)(el.attributes.updatedAt),
                    updatedDateTimeAt: (0, parsers_1.transformDateTime)(el.attributes.updatedAt)
                };
            }),
            status: response.status,
            errors: null,
            meta: (0, parsers_1.transformPagination)(purchaseSubjects.meta)
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePurchaseSubjects = parsePurchaseSubjects;
const parsePurchaseSubjectsForSelect = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const purchaseSubjects = response.content;
    try {
        return {
            data: purchaseSubjects.data.map(el => {
                return {
                    id: el.id,
                    label: el.attributes.itemTitle
                };
            }),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: response.errors };
    }
};
exports.parsePurchaseSubjectsForSelect = parsePurchaseSubjectsForSelect;
const parsePurchaseSubject = (response) => {
    if (response.content === null)
        return { data: null, errors: response.errors, status: response.status };
    const subject = response.content;
    try {
        const category = subject.data.relationships.inventoryCategory;
        return {
            data: {
                id: subject.data.id,
                verified: subject.data.attributes.verified ? types_1.VerifiedStatus.VERIFIED : types_1.VerifiedStatus.UNVERIFIED,
                subject: subject.data.relationships.inventoryItem && {
                    id: subject.data.relationships.inventoryItem.data.id,
                    label: subject.data.relationships.inventoryItem.data.attributes.name
                },
                specification: subject.data.relationships.inventorySpecification && {
                    id: subject.data.relationships.inventorySpecification.data.id,
                    label: subject.data.relationships.inventorySpecification.data.attributes.specification
                },
                category: category && {
                    id: category.data.id,
                    label: (category.data.attributes.code && category.data.attributes.code + ' - ' + category.data.attributes.name)
                        || category.data.attributes.name
                },
                subjectTitle: subject.data.attributes.itemTitle,
                type: subject.data.attributes.type
            },
            status: response.status,
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return helper_1.errorObject;
    }
};
exports.parsePurchaseSubject = parsePurchaseSubject;
const parsePurchaseSubjectCharacteristics = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const purchaseSubject = response.content;
    try {
        return {
            data: purchaseSubject.data.map(el => parseCharacteristics(el)),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: [{ name: 'parsers', message: 'მოხდა გაუთვალისწინებელი შემთხვევა' }] };
    }
};
exports.parsePurchaseSubjectCharacteristics = parsePurchaseSubjectCharacteristics;
const parsePurchaseSubjectCollection = (response) => {
    if (response.content === null)
        return { data: [], errors: response.errors };
    const purchaseSubject = response.content;
    try {
        return {
            data: purchaseSubject.data.map(el => parseSubjectCollection(el)),
            meta: (0, parsers_1.transformPagination)(purchaseSubject.meta),
            errors: null
        };
    }
    catch (err) {
        (0, helper_1.throwException)(err);
        return { data: [], errors: [{ name: 'parsers', message: 'მოხდა გაუთვალისწინებელი შემთხვევა' }] };
    }
};
exports.parsePurchaseSubjectCollection = parsePurchaseSubjectCollection;
const parseCharacteristics = (data) => {
    return {
        id: data.attributes.id,
        assignment: data.attributes.assignment,
        characteristic: data.attributes.characteristics,
        quantity: data.attributes.quantity,
        documentLink: documentLink[data.attributes.type] + data.attributes.id + '/edit',
        documentNumber: data.attributes.number,
        createdAt: (0, parsers_1.transformDate)(data.attributes.createdAt),
        updatedAt: (0, parsers_1.transformDate)(data.attributes.updatedAt)
    };
};
const parseSubjectCollection = (data) => {
    return {
        id: data.id,
        name: data.attributes.itemTitle,
        className: !data.attributes.verified ? 'bg-warning-o-15' : '',
        verified: data.attributes.verified,
        category: data.attributes.categoryTitle || '',
        specification: data.attributes.specificationTitle || '',
        type: data.attributes.type
    };
};
const documentLink = {
    ConditionalSubmission: process.env.BASE_URL + '/documents/purchases/conditional-submissions/',
    Submission: process.env.BASE_URL + '/documents/purchases/submissions/'
};
