"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createEducationalInstitution = exports.getEducationalInstitutionsForSelect = void 0;
const tslib_1 = require("tslib");
const Api = tslib_1.__importStar(require("api/privateRequest"));
const parsers_1 = require("api/references/employees/educationalInstitution/parsers");
const getEducationalInstitutionsForSelect = async (params) => {
    const response = await Api.get('references/educational-institutions', params);
    return (0, parsers_1.parseEducationalInstitutionsForSelect)(response);
};
exports.getEducationalInstitutionsForSelect = getEducationalInstitutionsForSelect;
const createEducationalInstitution = async (params) => {
    const response = await Api.post('references/educational-institutions', params);
    return (0, parsers_1.parseEducationalInstitution)(response);
};
exports.createEducationalInstitution = createEducationalInstitution;
