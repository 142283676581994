"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubmissionsSubStatuses = exports.SubmissionsStatuses = exports.VerifyItemStatus = exports.ExpirationDateStatus = void 0;
var ExpirationDateStatus;
(function (ExpirationDateStatus) {
    ExpirationDateStatus[ExpirationDateStatus["CONFIRMED"] = 1] = "CONFIRMED";
    ExpirationDateStatus[ExpirationDateStatus["DECLINED"] = 2] = "DECLINED";
})(ExpirationDateStatus || (exports.ExpirationDateStatus = ExpirationDateStatus = {}));
var VerifyItemStatus;
(function (VerifyItemStatus) {
    VerifyItemStatus[VerifyItemStatus["PENDING"] = 1] = "PENDING";
    VerifyItemStatus[VerifyItemStatus["CONFIRMED"] = 2] = "CONFIRMED";
    VerifyItemStatus[VerifyItemStatus["DECLINED"] = 3] = "DECLINED";
    VerifyItemStatus[VerifyItemStatus["SUSPENDED"] = 4] = "SUSPENDED";
})(VerifyItemStatus || (exports.VerifyItemStatus = VerifyItemStatus = {}));
var SubmissionsStatuses;
(function (SubmissionsStatuses) {
    SubmissionsStatuses[SubmissionsStatuses["DRAFT"] = 1] = "DRAFT";
    SubmissionsStatuses[SubmissionsStatuses["CHECKING_PROCESS"] = 2] = "CHECKING_PROCESS";
    SubmissionsStatuses[SubmissionsStatuses["VERIFICATION_PROCESS"] = 3] = "VERIFICATION_PROCESS";
    SubmissionsStatuses[SubmissionsStatuses["CONFIRMATION_PROCESS"] = 4] = "CONFIRMATION_PROCESS";
    SubmissionsStatuses[SubmissionsStatuses["CONFIRMED"] = 5] = "CONFIRMED";
    SubmissionsStatuses[SubmissionsStatuses["PARTIALLY_CONFIRMED"] = 6] = "PARTIALLY_CONFIRMED";
    SubmissionsStatuses[SubmissionsStatuses["DECLINED"] = 7] = "DECLINED";
    SubmissionsStatuses[SubmissionsStatuses["SUSPENDED"] = 8] = "SUSPENDED";
    SubmissionsStatuses[SubmissionsStatuses["FOR_CORRECTION"] = 9] = "FOR_CORRECTION";
})(SubmissionsStatuses || (exports.SubmissionsStatuses = SubmissionsStatuses = {}));
var SubmissionsSubStatuses;
(function (SubmissionsSubStatuses) {
    SubmissionsSubStatuses[SubmissionsSubStatuses["PRICE_RESEARCH"] = 1] = "PRICE_RESEARCH";
})(SubmissionsSubStatuses || (exports.SubmissionsSubStatuses = SubmissionsSubStatuses = {}));
